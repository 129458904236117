import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs } from "antd";
import IForecast from "../../../../interfaces/IForecast";
import { numberFormat } from "highcharts";

const { Title } = Typography;
const { TabPane } = Tabs;

const TotalGains = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [owner, setOwner] = useState([
    ...forecast[0].ages.owner_ages.map((o, i) => {
      return {
        key: "owner" + i,
        name: o.name,
      };
    }),
  ]);

  const [baseCost, setBaseCost] = useState(
    owner.map((o, i) => {
      return {
        key: "baseCost" + i,
        name: "Base Cost",
      };
    })
  );

  const [accumulative, setAccumulative] = useState(
    owner.map((o, i) => {
      return {
        key: "accumulative" + i,
        name: "Accumulative Gain",
      };
    })
  );

  const [recognizedBase, setRecognizedBase] = useState(
    owner.map((o, i) => {
      return {
        key: "recognizedBase" + i,
        name: "% Recognized as Base Cost",
      };
    })
  );

  const [recognizedGain, setRecognizedGain] = useState(
    owner.map((o, i) => {
      return {
        key: "recognizedGain" + i,
        name: "% Recognized as Gain",
      };
    })
  );

  const [baseCostDrawdown, setBaseCostDrawdown] = useState(
    owner.map((o, i) => {
      return {
        key: "baseCostDrawdown" + i,
        name: "Base Cost Drawdown",
      };
    })
  );

  const [gainDrawdown, setGainDrawdown] = useState(
    owner.map((o, i) => {
      return {
        key: "gainDrawdown" + i,
        name: "Gain Drawdown From Prior Year",
      };
    })
  );

  useEffect(() => {
    owner.map((o, index) => {
      let newBaseCost: any = [...baseCost];
      forecast.map((f, i) => {
        newBaseCost[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_gains_from_other_assets.base_cost
          ),
          0,
          ".",
          ","
        );
      });
      setBaseCost(newBaseCost);

      let newAccumulative: any = [...accumulative];
      forecast.map((f, i) => {
        newAccumulative[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_gains_from_other_assets.accumulattive_gain
          ),
          0,
          ".",
          ","
        );
      });
      setAccumulative(newAccumulative);

      let newRecognizedBase: any = [...recognizedBase];
      forecast.map((f, i) => {
        newRecognizedBase[index]["year" + i] =
          numberFormat(
            Math.round(
              f.household_expenses.additional_tax_charge.details[index]
                .total_gains_from_other_assets.rate_recognised_as_base_cost *
                100
            ),
            0,
            ".",
            ","
          ) + "%";
      });
      setRecognizedBase(newRecognizedBase);

      let newRecognizedGain: any = [...recognizedGain];
      forecast.map((f, i) => {
        newRecognizedGain[index]["year" + i] =
          numberFormat(
            Math.round(
              f.household_expenses.additional_tax_charge.details[index]
                .total_gains_from_other_assets.rate_recognised_as_gain * 100
            ),
            0,
            ".",
            ","
          ) + "%";
      });
      setRecognizedGain(newRecognizedGain);

      let newBaseCostDrawdown: any = [...baseCostDrawdown];
      forecast.map((f, i) => {
        newBaseCostDrawdown[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_gains_from_other_assets.base_cost_drawdown
          ),
          0,
          ".",
          ","
        );
      });
      setBaseCostDrawdown(newBaseCostDrawdown);

      let newGainDrawdown: any = [...gainDrawdown];
      forecast.map((f, i) => {
        newGainDrawdown[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_gains_from_other_assets.gain_drawdown
          ),
          0,
          ".",
          ","
        );
      });
      setGainDrawdown(newGainDrawdown);
    });
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" type="card">
        {owner.map((o, i) => {
          return (
            <TabPane tab={"T" + o.name} key={i}>
              <Table
                columns={Columns}
                dataSource={[
                  baseCost[i],
                  accumulative[i],
                  recognizedBase[i],
                  recognizedGain[i],
                  baseCostDrawdown[i],
                  gainDrawdown[i],
                ]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TotalGains;
