import { IInputs } from "../interfaces/ISubInputs";

const initialInputs: IInputs = {
  assumptions_set_id: "",
  input_set_name: "Plan - " + Math.floor(Math.random() * 999),
  current_year: 2021,
  household_owners: [
    {
      id: "",
      name: "",
      birth_year: 1989,
      retirement_age: 65,
    },
  ],
  children: [],
  assets: {
    properties: [],
    bank_accounts: {
      original_balance: 0,
      minimum_cash_balance_acceptable: 0,
    },
    savings_and_investments: {
      individual_savings_account: [
        {
          original_balance: 0,
          annual_contribution: 0,
        },
      ],
      general_investment_account: [
        {
          original_balance: 0,
          annual_contribution: 0,
        },
      ],
    },
    non_employment_defined_contribution_pension_plans: [
      {
        original_balance: 0,
        annual_contribution: 0,
      },
    ],
  },
  liabilities: {
    mortgages: [],
    other_loans: [],
    credit_card: {
      id: "",
      name: "Credit Card",
      original_balance: 0,
      interest_rate: 0,
    },
  },
  household_income: {
    employment_income: [
      {
        gross_anual_amount: 0,
        member_contribution: 0,
        employer_contribution: 0,
        inflation: 0,
        start_year: 2021,
        end_year: 2090,
      },
    ],
    self_employment_income: [
      {
        gross_anual_amount: 0,
      },
    ],
    rental_income: {
      joint_annual_rental_income: 0,
      details: [
        {
          share_of_rental_income: 1,
          start_year: 2021,
        },
      ],
    },
    dividend_income: [
      {
        anual_amount: 0,
        start_year: 2021,
        end_year: 2021,
      },
    ],
    savings_and_investments_drawdowns: {
      individual_savings_accounts: [
        {
          owner_name: "Mr Optimistic",
          drawdowns: [],
        },
      ],
      general_investment_accounts: [
        {
          owner_name: "Mr Optimistic",
          drawdowns: [],
        },
      ],
    },
    pension_income: {
      state_pension: [
        {
          annual_amount: 0,
          inflation: 0,
          state_pension_age: 65,
          start_year: 2055,
          end_year: 2090,
        },
      ],
      defined_benifit_pension_plans: [
        {
          option_taken: "Lump Sum",
          estimated_lump_sum: 0,
          estimated_annual_pension: 0,
          annual_increase: 0,
        },
      ],
      defined_contribution_pension_plans: [
        {
          option_taken: "Drawdown",
          drawdown_option_annual_amount: 0,
        },
      ],
    },
    other_income: {
      other_taxable_income: [
        {
          name: "Mr Optimistic",
          gross_annual_amount: 0,
          inflation: 0,
          start_year: 2021,
          end_year: 2021,
        },
      ],
      other_non_taxable_income: [
        {
          name: "Mr Optimistic",
          gross_annual_amount: 0,
          inflation: 0,
          start_year: 2021,
          end_year: 2021,
        },
      ],
    },
  },
  household_expenses: {
    blanket_inflation_rate: 0,
    housing: {
      details: [],
      total: 0,
    },
    consumables: {
      details: [],
      total: 0,
    },
    travel: {
      details: [],
      total: 0,
    },
    shopping: {
      details: [],
      total: 0,
    },
    entertainment: {
      details: [],
      total: 0,
    },
    holiday: {
      details: [],
      total: 0,
    },
    insurance_policies: {
      life_insurance: [
        {
          name: "Life Insurance - ",
          annual_expense: 0,
          inflation: 0,
          rate_after_retirement: 0,
        },
      ],
      critical_illness_cover: [
        {
          name: "Critical Illness Cover - ",
          annual_expense: 0,
          inflation: 0,
          rate_after_retirement: 0,
        },
      ],
      family_income_benefit: [
        {
          name: "Family Income Benifit - ",
          annual_expense: 0,
          inflation: 0,
          rate_after_retirement: 0,
        },
      ],
    },
    one_off_expenses: [],
    children_education_expenses: {
      primary_school_fees: {
        annual_fee_in_todays_terms: 0,
        inflation: 0,
      },
      seconday_school_fees: {
        annual_fee_in_todays_terms: 0,
        inflation: 0,
      },
      university_fees: {
        annual_fee_in_todays_terms: 0,
        inflation: 0,
      },
    },
  },
};

export default initialInputs;
