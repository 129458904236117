import { Card, Col, Layout, Row, Button } from "antd";
import Sdlt from "./assumptionsComponents/Sdlt";
import Isaa from "./assumptionsComponents/Isaa";
import Pension from "./assumptionsComponents/Pension";
import IncomeTax from "./assumptionsComponents/IncomeTax";
import IncomeLimits from "./assumptionsComponents/IncomeLimits";
import IncomeLimits2 from "./assumptionsComponents/IncomeLimits2";
import EmploymentNic from "./assumptionsComponents/EmploymentNic";
import SelfEmployment2 from "./assumptionsComponents/SelfEmployment2";
import SelfEmployment4 from "./assumptionsComponents/SelfEmployment4";
import DividendTax from "./assumptionsComponents/DividendTax";
import MarketData from "./assumptionsComponents/MarketData";
import React from "react";
import EmploymentMinimumPensionContributions from "./assumptionsComponents/EmploymentMinimumPensionContributions";
import ResidentialPropertyCapitalTexRateThresholds from "./assumptionsComponents/ResidentialPropertyCapitalTexRateThresholds";
import OtherAssetsCapitalGainsTaxRateThresholds from "./assumptionsComponents/OtherAssetsCapitalGainsTaxRateThresholds";
import { useHistory } from "react-router-dom";
import IAssumptions from "../../interfaces/IAssumptions";

const Assumption = (props: any) => {
  const history = useHistory();

  const assumptions: IAssumptions = props.assumptions;

  return (
    <Row>
      <Col span={24}>
        <Sdlt assumptions={assumptions} />
        <Isaa assumptions={assumptions} />
        <Pension assumptions={assumptions} />
        <IncomeTax assumptions={assumptions} />
        <IncomeLimits assumptions={assumptions} />
        <EmploymentMinimumPensionContributions assumptions={assumptions} />
        <EmploymentNic assumptions={assumptions} />
        <SelfEmployment2 assumptions={assumptions} />
        <SelfEmployment4 assumptions={assumptions} />
        <DividendTax assumptions={assumptions} />
        <ResidentialPropertyCapitalTexRateThresholds
          assumptions={assumptions}
        />
        <OtherAssetsCapitalGainsTaxRateThresholds assumptions={assumptions} />
        <IncomeLimits2 assumptions={assumptions} />
        <MarketData assumptions={assumptions} />
      </Col>
    </Row>
  );
};

export default Assumption;
