import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

import { persistReducer, persistStore } from "redux-persist";
//@ts-ignore
import storage from "redux-persist/lib/storage";

//reducers
import { userReducer } from "./auth/auth";
import { loadingReducer } from "./general/loading";
import { assumptionReducer } from "./assumptions/assumptions";
import { inputsReducer, currentInputSetReducer } from "./inputs/inputs";
import { activeClientReducer, clientsReducer } from "./clients/client";
import { summaryReducer } from "./summary/summary";
import { realSummaryReducer } from "./summary/realSummary";
import { eventsReducer } from "./events/events";

const rootReducers = combineReducers({
  userReducer,
  loadingReducer,
  assumptionReducer,
  inputsReducer,
  clientsReducer,
  currentInputSetReducer,
  summaryReducer,
  activeClientReducer,
  realSummaryReducer,
  eventsReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
//store
const initialState: any = {};
const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
export default store;
