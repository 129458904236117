import React from "react";
import { Row, Col, Form, Typography } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import DateInput from "../../controls/DateInput";
import RateInput from "../../controls/RateInput";
import IAssumptions from "../../../../interfaces/IAssumptions";

const { Text } = Typography;

const Rental = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;
  return (
    <div>
      {" "}
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <Text>Joint annual rental income</Text>
          </Form.Item>
        </Col>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <MoneyInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.household_income.rental_income.joint_annual_rental_income =
                    +e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.household_income.rental_income.joint_annual_rental_income = 0;
                  setInitialInputs(clone);
                }
              }}
              value={`${initialInputs.household_income.rental_income.joint_annual_rental_income}`}
            />
          </Form.Item>
        </Col>
      </Row>
      {initialInputs.household_income.rental_income.details.map(
        (s: any, i: any) => {
          return (
            <Row key={"rental" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text strong>{initialInputs.household_owners[i].name}</Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Share of income:">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.rental_income.details[
                          i
                        ].share_of_rental_income = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.rental_income.details[
                          i
                        ].share_of_rental_income = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.rental_income.details[i].share_of_rental_income}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Start year:">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_income.rental_income.details[
                        i
                      ].start_year = +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_income.rental_income.details[
                      i
                    ].start_year.toString()}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Growth Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialAssumption };
                        clone.market_data.retain_price_index.rate = +e;
                        setInitialAssumption(clone);
                      } else {
                        const clone = { ...initialAssumption };
                        clone.market_data.retain_price_index.rate = 0;
                        setInitialAssumption(clone);
                      }
                    }}
                    value={`${initialAssumption.market_data.retain_price_index.rate}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default Rental;
