import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs } from "antd";
import IForecast from "../../../../interfaces/IForecast";
import { numberFormat } from "highcharts";

const { Title } = Typography;
const { TabPane } = Tabs;

const CapitalGains = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [owner, setOwner] = useState([
    ...forecast[0].ages.owner_ages.map((o, i) => {
      return {
        key: "owner" + i,
        name: o.name,
      };
    }),
  ]);

  const [totalGainProperty, setTotalGainProperty] = useState(
    owner.map((o, i) => {
      return {
        key: "totalGainProperty" + i,
        name: "Total Gain from Property Sale",
      };
    })
  );

  const [totalGainOther, setTotalGainOther] = useState(
    owner.map((o, i) => {
      return {
        key: "totalGainOther" + i,
        name: "Total Gain from Other Assets",
      };
    })
  );

  const [annualExemptionProperty, setAnnualExemptionProperty] = useState(
    owner.map((o, i) => {
      return {
        key: "annualExemptionProperty" + i,
        name: " Annual Exemption Amount (Property)",
      };
    })
  );

  const [annualExemptionOther, setAnnualExemptionOther] = useState(
    owner.map((o, i) => {
      return {
        key: "annualExemptionOther" + i,
        name: "Less: Annual Exemption Amount (Other Assets)",
      };
    })
  );

  const [taxableGainProperty, setTaxableGainProperty] = useState(
    owner.map((o, i) => {
      return {
        key: "taxableGainProperty" + i,
        name: "Taxable Gains from Property Sale",
      };
    })
  );

  const [taxableGainOther, setTaxableGainOther] = useState(
    owner.map((o, i) => {
      return {
        key: "taxableGainOther" + i,
        name: "Taxable Gain from Other Assets",
      };
    })
  );

  useEffect(() => {
    owner.map((o, index) => {
      let newTotalGainProperty: any = [...totalGainProperty];
      forecast.map((f, i) => {
        newTotalGainProperty[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains.taxable_gains_from_property_sale
          ),
          0,
          ".",
          ","
        );
      });
      setTotalGainProperty(newTotalGainProperty);

      let newTotalGainOther: any = [...totalGainOther];
      forecast.map((f, i) => {
        newTotalGainOther[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains.total_gain_from_other_assets
          ),
          0,
          ".",
          ","
        );
      });
      setTotalGainOther(newTotalGainOther);

      let newAnnualExemptionProperty: any = [...annualExemptionProperty];
      forecast.map((f, i) => {
        newAnnualExemptionProperty[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains.annual_exemption_amount_property
          ),
          0,
          ".",
          ","
        );
      });
      setAnnualExemptionProperty(newAnnualExemptionProperty);

      let newAnnualExemptionOther: any = [...annualExemptionOther];
      forecast.map((f, i) => {
        newAnnualExemptionOther[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains.annual_exemption_amount_other_assets
          ),
          0,
          ".",
          ","
        );
      });
      setAnnualExemptionOther(newAnnualExemptionOther);

      let newTaxableGainProperty: any = [...taxableGainProperty];
      forecast.map((f, i) => {
        newTaxableGainProperty[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains.taxable_gains_from_property_sale
          ),
          0,
          ".",
          ","
        );
      });
      setTaxableGainProperty(newTaxableGainProperty);

      let newTaxableGainOther: any = [...taxableGainOther];
      forecast.map((f, i) => {
        newTaxableGainOther[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains.taxable_gains_from_other_assets
          ),
          0,
          ".",
          ","
        );
      });
      setTaxableGainOther(newTaxableGainOther);
    });
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" type="card">
        {owner.map((o, i) => {
          return (
            <TabPane tab={o.name} key={i}>
              <Table
                columns={Columns}
                dataSource={[
                  totalGainProperty[i],
                  totalGainOther[i],
                  annualExemptionProperty[i],
                  annualExemptionOther[i],
                  taxableGainProperty[i],
                  taxableGainOther[i],
                ]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default CapitalGains;
