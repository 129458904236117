import React, { useEffect } from "react";
import { Row, Col, Form } from "antd";
import TextInput from "../../controls/TextInput";
import DateInput from "../../controls/DateInput";
import RemoveButton from "../RemoveButton";
import { IInputs } from "../../../../interfaces/ISubInputs";
import removeAt from "../../../../helpers/remoteAt";

const Children = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  useEffect(() => {
    console.log("Ran");
  }, [initialInputs]);
  return (
    <div>
      {initialInputs.children.map((c: any, i: any) => {
        return (
          <Row key={"children" + i}>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Child Name">
                <TextInput
                  placeholder="Name"
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.children[i].name = e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.children[i].name = "";
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.children[i].name}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Birth Year">
                <DateInput
                  onBlur={(date, dateString) => {
                    if (dateString) {
                      const clone = { ...initialInputs };
                      clone.children[i].birth_year = +dateString;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.children[i].birth_year = 2024;
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.children[i].birth_year.toString()}
                />
              </Form.Item>
            </Col>
            <RemoveButton
              onClick={() => {
                const clone = { ...initialInputs };
                clone.children = removeAt(clone.children, i);
                setInitialInputs(clone);
              }}
            />
          </Row>
        );
      })}
    </div>
  );
};

export default Children;
