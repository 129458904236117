import React, { useState } from "react";
import { Row, Col, Form, InputNumber, Input } from "antd";
import TextInput from "../../controls/TextInput";
import DateInput from "../../controls/DateInput";
import RemoveButton from "../RemoveButton";
import { IInputs } from "../../../../interfaces/ISubInputs";
import removeAt from "../../../../helpers/remoteAt";

const Onwner = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const [test, setTest] = useState("asd");

  return (
    <div>
      {initialInputs.household_owners.map((o: any, i: any) => {
        return (
          <Row key={"owner" + i}>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Owner Name">
                <TextInput
                  placeholder="Name"
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.household_owners[i].name = e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.household_owners[i].name = "";
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.household_owners[i].name}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Birth Year">
                <DateInput
                  onBlur={(date, dateString) => {
                    if (dateString) {
                      const clone = { ...initialInputs };
                      clone.household_owners[i].birth_year = +dateString;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.household_owners[i].birth_year = 1989;
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.household_owners[
                    i
                  ].birth_year.toString()}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Retirement Age">
                <InputNumber
                  className="custom-input-fields"
                  name="retirement_age"
                  value={initialInputs.household_owners[
                    i
                  ].retirement_age.toString()}
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.household_owners[i].retirement_age =
                        +e.target.value;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.household_owners[i].retirement_age = 65;
                      setInitialInputs(clone);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <RemoveButton
              onClick={() => {
                const clone: IInputs = { ...initialInputs };

                clone.household_owners = removeAt(clone.household_owners, i);

                clone.assets.savings_and_investments.individual_savings_account =
                  removeAt(
                    clone.assets.savings_and_investments
                      .individual_savings_account,
                    i
                  );

                clone.assets.savings_and_investments.general_investment_account =
                  removeAt(
                    clone.assets.savings_and_investments
                      .general_investment_account,
                    i
                  );

                clone.assets.non_employment_defined_contribution_pension_plans =
                  removeAt(
                    clone.assets
                      .non_employment_defined_contribution_pension_plans,
                    i
                  );

                clone.household_income.employment_income = removeAt(
                  clone.household_income.employment_income,
                  i
                );
                clone.household_income.self_employment_income = removeAt(
                  clone.household_income.self_employment_income,
                  i
                );
                clone.household_income.rental_income.details = removeAt(
                  clone.household_income.rental_income.details,
                  i
                );
                clone.household_income.dividend_income = removeAt(
                  clone.household_income.dividend_income,
                  i
                );
                clone.household_income.other_income.other_taxable_income =
                  removeAt(
                    clone.household_income.other_income.other_taxable_income,
                    i
                  );
                clone.household_income.other_income.other_non_taxable_income =
                  removeAt(
                    clone.household_income.other_income
                      .other_non_taxable_income,
                    i
                  );
                clone.household_income.savings_and_investments_drawdowns.individual_savings_accounts =
                  removeAt(
                    clone.household_income.savings_and_investments_drawdowns
                      .individual_savings_accounts,
                    i
                  );
                clone.household_income.savings_and_investments_drawdowns.general_investment_accounts =
                  removeAt(
                    clone.household_income.savings_and_investments_drawdowns
                      .general_investment_accounts,
                    i
                  );
                clone.household_income.pension_income.defined_benifit_pension_plans =
                  removeAt(
                    clone.household_income.pension_income
                      .defined_benifit_pension_plans,
                    i
                  );
                clone.household_income.pension_income.defined_contribution_pension_plans =
                  removeAt(
                    clone.household_income.pension_income
                      .defined_contribution_pension_plans,
                    i
                  );
                clone.household_income.other_income.other_taxable_income =
                  removeAt(
                    clone.household_income.other_income.other_taxable_income,
                    i
                  );
                clone.household_income.other_income.other_non_taxable_income =
                  removeAt(
                    clone.household_income.other_income
                      .other_non_taxable_income,
                    i
                  );
                clone.household_expenses.insurance_policies.life_insurance =
                  removeAt(
                    clone.household_expenses.insurance_policies.life_insurance,
                    i
                  );
                clone.household_expenses.insurance_policies.critical_illness_cover =
                  removeAt(
                    clone.household_expenses.insurance_policies
                      .critical_illness_cover,
                    i
                  );
                clone.household_expenses.insurance_policies.family_income_benefit =
                  removeAt(
                    clone.household_expenses.insurance_policies
                      .family_income_benefit,
                    i
                  );

                setInitialInputs(clone);
              }}
            />
          </Row>
        );
      })}
    </div>
  );
};

export default Onwner;
