import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../../interfaces/IForecast";

const Entertainment = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [entertainment, setEntertainment] = useState([
    ...forecast[0].household_expenses.entertainment.details.map((e, i) => {
      return {
        key: "Expenses" + i,
        name: e.name,
      };
    }),
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total Entertainment",
    },
  ]);

  useEffect(() => {
    entertainment.map((e: any, index) => {
      forecast.map((f, i) => {
        e["year" + i] = numberFormat(
          Math.round(f.household_expenses.entertainment.details[index].amount),
          0,
          ".",
          ","
        );
      });
    });

    let newTotal: any = total;
    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.entertainment.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [...entertainment, ...total];
  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default Entertainment;
