import React from "react";
import { Row, Col, Form, Typography, InputNumber } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";
import TextInput from "../../controls/TextInput";
import RemoveButton from "../RemoveButton";
import removeAt from "../../../../helpers/remoteAt";
import { IInputs } from "../../../../interfaces/ISubInputs";
import IAssumptions from "../../../../interfaces/IAssumptions";

const { Text } = Typography;

const Consumables = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;
  return (
    <div>
      {" "}
      {initialInputs.household_expenses.consumables.details.map(
        (e: any, i: any) => {
          return (
            <Row key={"consumables" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Expense:">
                  <TextInput
                    placeholder="Name"
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details[i].name =
                          e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details[i].name =
                          "";
                        setInitialInputs(clone);
                      }
                    }}
                    value={
                      initialInputs.household_expenses.consumables.details[i]
                        .name
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual expense:">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details[
                          i
                        ].annual_expense = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details[
                          i
                        ].annual_expense = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.consumables.details[i].annual_expense}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="% After retirement">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details[
                          i
                        ].rate_after_retirement = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details[
                          i
                        ].rate_after_retirement = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.consumables.details[i].rate_after_retirement}`}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Growth Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialAssumption };
                        clone.market_data.retain_price_index.rate = +e;
                        setInitialAssumption(clone);
                      } else {
                        const clone = { ...initialAssumption };
                        clone.market_data.retain_price_index.rate = 0;
                        setInitialAssumption(clone);
                      }
                    }}
                    value={`${initialAssumption.market_data.retain_price_index.rate}`}
                  />
                </Form.Item>
              </Col>

              <RemoveButton
                onClick={() => {
                  const clone = { ...initialInputs };
                  clone.household_expenses.consumables.details = removeAt(
                    clone.household_expenses.consumables.details,
                    i
                  );
                  setInitialInputs(clone);
                }}
              />
            </Row>
          );
        }
      )}
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <Text>Total:</Text>
          </Form.Item>
        </Col>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <InputNumber
              value={initialInputs.household_expenses.consumables.details.reduce(
                (a: number, b: any) => a + b.annual_expense,
                0
              )}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              disabled
              className="custom-input-fields"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Consumables;
