import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useEffect, useState } from "react";
import IForecast from "../../../../interfaces/IForecast";

const CreditCard = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [beginning, setBeginning] = useState([
    {
      key: "1",
      name: "Beginning Of Period",
    },
  ]);
  const [change, setChange] = useState([
    {
      key: "2",
      name: "Change in Year",
    },
  ]);
  const [end, setEnd] = useState([
    {
      key: "3",
      name: "End of Period",
    },
  ]);

  useEffect(() => {
    let BoP: any = {
      key: 1,
      name: "Beginning Of Period",
    };
    forecast.map((f, i) => {
      BoP["year" + i] = numberFormat(
        Math.round(f.creditors.credit_cards.beginning_of_period),
        0,
        ".",
        ","
      );
    });
    setBeginning(BoP);

    let newChange: any = {
      key: "2",
      name: "Change in Year",
    };
    forecast.map((f, i) => {
      newChange["year" + i] = numberFormat(
        Math.round(f.creditors.credit_cards.change_in_year),
        0,
        ".",
        ","
      );
    });
    setChange(newChange);

    let newEnd: any = {
      key: "3",
      name: "Change in Year",
    };
    forecast.map((f, i) => {
      newEnd["year" + i] = numberFormat(
        Math.round(f.creditors.credit_cards.end_of_period),
        0,
        ".",
        ","
      );
    });
    setEnd(newEnd);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [beginning, change, end];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default CreditCard;
