import { Table, Typography, Tabs } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../../interfaces/IForecast";

const { Title } = Typography;
const { TabPane } = Tabs;

const Employment = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [owner, setOwner] = useState([
    ...forecast[0].ages.owner_ages.map((o, i) => {
      return {
        key: "owner" + i,
        name: o.name,
      };
    }),
  ]);

  const [gross, setGross] = useState(
    owner.map((o, i) => {
      return {
        key: "gross" + i,
        name: "Gross Salary",
      };
    })
  );

  const [memberPension, setMemberPension] = useState(
    owner.map((o, i) => {
      return {
        key: "memberPension" + i,
        name: "Member's Pension Contribution",
      };
    })
  );

  const [netGross, setNetGross] = useState(
    owner.map((o, i) => {
      return {
        key: "netGross" + i,
        name: "Net Gross Salary",
      };
    })
  );

  const [incomeTax, setIncomeTax] = useState(
    owner.map((o, i) => {
      return {
        key: "incomeTax" + i,
        name: "Income Tax Charge",
      };
    })
  );

  const [limitOnPersonal, setLimitOnPersonal] = useState(
    owner.map((o, i) => {
      return {
        key: "limit" + i,
        name: "Limit on Personal Allowance",
      };
    })
  );

  const [nicClass, setNicClass] = useState(
    owner.map((o, i) => {
      return {
        key: "nic" + i,
        name: "NIC Class 1 Charge",
      };
    })
  );

  const [netSalary, setNetSalary] = useState(
    owner.map((o, i) => {
      return {
        key: "netSalary" + i,
        name: "Net Salary",
      };
    })
  );

  const [effective, setEffective] = useState(
    owner.map((o, i) => {
      return {
        key: "effective" + i,
        name: "Effective Tax Rate",
      };
    })
  );

  const [total, setTotal] = useState([
    {
      name: "Total Employment Income",
    },
  ]);

  useEffect(() => {
    owner.map((o, index) => {
      let newGross: any = [...gross];
      forecast.map((f, i) => {
        newGross[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index].gross_salary
          ),
          0,
          ".",
          ","
        );
      });
      setGross(newGross);

      let newMemberPension: any = [...memberPension];
      forecast.map((f, i) => {
        newMemberPension[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index]
              .members_pension_contribution
          ),
          0,
          ".",
          ","
        );
      });
      setMemberPension(newMemberPension);

      let newNetGross: any = [...netGross];
      forecast.map((f, i) => {
        newNetGross[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index]
              .total_gross_salary_after_less
          ),
          0,
          ".",
          ","
        );
      });
      setNetGross(newNetGross);

      let newIncomeTax: any = [...incomeTax];
      forecast.map((f, i) => {
        newIncomeTax[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index]
              .income_tax_charge
          ),
          0,
          ".",
          ","
        );
      });
      setIncomeTax(newIncomeTax);

      let newLimitOnPersonal: any = [...limitOnPersonal];
      forecast.map((f, i) => {
        newLimitOnPersonal[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index]
              .limit_on_personal_allowance
          ),
          0,
          ".",
          ","
        );
      });
      setLimitOnPersonal(newLimitOnPersonal);

      let newNic: any = [...nicClass];
      forecast.map((f, i) => {
        newNic[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index]
              .nic_class_1_charge
          ),
          0,
          ".",
          ","
        );
      });
      setNicClass(newNic);

      let newNetSalary: any = [...netSalary];
      forecast.map((f, i) => {
        newNetSalary[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.employment_income.details[index].net_salary
          ),
          0,
          ".",
          ","
        );
      });
      setNetSalary(newNetSalary);

      let newEffective: any = [...effective];
      forecast.map((f, i) => {
        newEffective[index]["year" + i] =
          numberFormat(
            Math.round(
              f.household_income.employment_income.details[index]
                .effective_tax_rate * 100
            ),
            0,
            ".",
            ","
          ) + " %";
      });
      setEffective(newEffective);
    });

    let newTotal: any = total;
    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.household_income.employment_income.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" type="card">
        {owner.map((o, i) => {
          return (
            <TabPane tab={o.name} key={i}>
              <Table
                columns={Columns}
                dataSource={[
                  gross[i],
                  memberPension[i],
                  netGross[i],
                  incomeTax[i],
                  limitOnPersonal[i],
                  nicClass[i],
                  netSalary[i],
                  effective[i],
                ]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          );
        })}
        <TabPane tab="Total Employment" key="11">
          <Title level={5}>Total Employment Income</Title>
          <Table
            columns={Columns}
            dataSource={[...total]}
            scroll={{ x: true }}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Employment;
