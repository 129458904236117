import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../../interfaces/IForecast";

const Financial = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [otherLoan, setOtherLoan] = useState([
    ...forecast[0].household_expenses.financials.other_loans.details.map(
      (ol, i) => {
        return {
          key: "otherLoan" + i,
          name: ol.name,
        };
      }
    ),
  ]);

  const [otherTotal, setOtherTotal] = useState([
    {
      key: "1",
      name: "Total Other Loan",
    },
  ]);

  const [savings, setSavings] = useState([
    ...forecast[0].household_expenses.financials.savings_and_investments.individual_savings_accounts.details.map(
      (s: any, i) => {
        return {
          key: "individual" + i,
          name: s.name,
        };
      }
    ),
  ]);
  const [general, setGeneral] = useState([
    ...forecast[0].household_expenses.financials.savings_and_investments.general_investment_accounts.details.map(
      (s: any, i) => {
        return {
          key: "general" + i,
          name: s.name,
        };
      }
    ),
  ]);

  const [totalSai, setTotalSai] = useState([
    {
      key: "2",
      name: "Total Savings and Investments",
    },
  ]);

  const [pension, setPension] = useState([
    ...forecast[0].household_expenses.financials.pension_pot.details.map(
      (s: any, i) => {
        return {
          key: "pension" + i,
          name: s.name,
        };
      }
    ),
  ]);
  const [life, setLife] = useState([
    ...forecast[0].household_expenses.financials.insurance_policies.life_insurance.details.map(
      (s: any, i) => {
        return {
          key: "insurance-life" + i,
          name: s.name,
        };
      }
    ),
  ]);
  const [critical, setCritical] = useState([
    ...forecast[0].household_expenses.financials.insurance_policies.critical_illness_cover.details.map(
      (s: any, i) => {
        return {
          key: "insurance-critial" + i,
          name: s.name,
        };
      }
    ),
  ]);
  const [family, setFanily] = useState([
    ...forecast[0].household_expenses.financials.insurance_policies.life_insurance.details.map(
      (s: any, i) => {
        return {
          key: "insurance-family" + i,
          name: s.name,
        };
      }
    ),
  ]);

  const [totalPension, setTotalPension] = useState([
    {
      key: "3",
      name: "Total Pension Pot",
    },
  ]);

  const [credit, setCredit] = useState([
    {
      name: "Credit Card",
    },
  ]);
  const [totalInsurance, setTotalInsurance] = useState([
    {
      name: "Total Insurance",
    },
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total Financial Expenses",
    },
  ]);

  useEffect(() => {
    otherLoan.map((e: any, index) => {
      forecast.map((f, i) => {
        e["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.other_loans.details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    let other: any = otherTotal;
    forecast.map((f, i) => {
      other[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.financials.other_loans.total),
        0,
        ".",
        ","
      );
    });

    savings.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.savings_and_investments
              .individual_savings_accounts.details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    general.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.savings_and_investments
              .general_investment_accounts.details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    pension.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.pension_pot.details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });
    life.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.insurance_policies.life_insurance
              .details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });
    critical.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.insurance_policies
              .critical_illness_cover.details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });
    family.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.financials.insurance_policies
              .family_income_benefit.details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    let newTotalSai: any = totalSai;
    forecast.map((f, i) => {
      newTotalSai[0]["year" + i] = numberFormat(
        Math.round(
          f.household_expenses.financials.savings_and_investments.total
        ),
        0,
        ".",
        ","
      );
    });

    let newTotalPension: any = totalPension;
    forecast.map((f, i) => {
      newTotalPension[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.financials.pension_pot.total),
        0,
        ".",
        ","
      );
    });
    let newCredit: any = credit;
    forecast.map((f, i) => {
      newCredit[0]["year" + i] = numberFormat(
        Math.round(
          f.household_expenses.financials.interest_expenses.details.amount
        ),
        0,
        ".",
        ","
      );
    });
    let newTotalInsurance: any = totalInsurance;
    forecast.map((f, i) => {
      newTotalInsurance[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.financials.insurance_policies.total),
        0,
        ".",
        ","
      );
    });

    let newTotal: any = total;
    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.financials.total),
        0,
        ".",
        ","
      );
    });
    setOtherTotal(other);
    setTotalSai(newTotalSai);
    setTotalPension(newTotalPension);
    setCredit(newCredit);
    setTotalInsurance(newTotalInsurance);
    setTotal(newTotal);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [
    {
      name: "Other Loans",
    },
    ...otherLoan,
    ...otherTotal,
    {
      name: "Savings and Investments",
    },
    ...savings,
    ...general,
    ...totalSai,
    {
      name: "Pension Pot",
    },
    ...pension,
    ...totalPension,
    {
      name: "Interest Expenses",
    },
    ...credit,
    {
      name: "Insurance Policies",
    },
    ...life,
    ...critical,
    ...family,
    ...totalInsurance,
    ...total,
  ];
  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default Financial;
