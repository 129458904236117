import React from "react";
import { Row, Col, Form, Typography, Button } from "antd";
import { v4 as uuid } from "uuid";
import { PlusCircleOutlined } from "@ant-design/icons/lib/icons";
import MoneyInput from "../../controls/MoneyInput";
import DateInput from "../../controls/DateInput";
import RemoveButton from "../RemoveButton";

const { Text } = Typography;

const GeneralInvestmentAccountDrawdown = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {initialInputs.household_income.savings_and_investments_drawdowns.individual_savings_accounts.map(
        (s: any, i: any) => {
          return (
            <div key={i + "generalInvestment"}>
              <Row key={"GeneralInvestment" + i}>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="custom-input-fields"
                >
                  <Form.Item label=" ">
                    <Text>
                      General Investment Account (GIA) -{" "}
                      <Text strong>
                        {initialInputs.household_owners[i].name}
                      </Text>
                      <Button
                        type="link"
                        onClick={() => {
                          const clone = { ...initialInputs };
                          clone.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                            i
                          ].drawdowns.push({
                            id: uuid(),
                            name: "Drawdown",
                            amount_to_drawn_down: 0,
                            start_year: 2055,
                            end_year: 2095,
                          });
                          setInitialInputs(clone);
                        }}
                      >
                        <PlusCircleOutlined />
                      </Button>
                    </Text>
                  </Form.Item>
                </Col>
              </Row>

              {initialInputs.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                i
              ].drawdowns.map((d: any, index: any) => {
                return (
                  <Row key={"generalDraw" + index}>
                    <Col
                      lg={4}
                      md={4}
                      sm={24}
                      xs={24}
                      className="custom-input-fields"
                    >
                      <Form.Item label=" ">
                        <Text>{`${
                          initialInputs.household_income
                            .savings_and_investments_drawdowns
                            .general_investment_accounts[i].drawdowns[index]
                            .name
                        } ${index + 1}`}</Text>
                      </Form.Item>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={24}
                      xs={24}
                      className="custom-input-fields"
                    >
                      <Form.Item label="Drawdown:">
                        <MoneyInput
                          onBlur={(e) => {
                            if (e) {
                              const clone = { ...initialInputs };
                              clone.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                                i
                              ].drawdowns[index].amount_to_drawn_down = +e;
                              setInitialInputs(clone);
                            } else {
                              const clone = { ...initialInputs };
                              clone.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                                i
                              ].drawdowns[index].amount_to_drawn_down = 0;
                              setInitialInputs(clone);
                            }
                          }}
                          value={`${initialInputs.household_income.savings_and_investments_drawdowns.general_investment_accounts[i].drawdowns[index].amount_to_drawn_down}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={24}
                      xs={24}
                      className="custom-input-fields"
                    >
                      <Form.Item label="Start year:">
                        <DateInput
                          onBlur={(date, dateString) => {
                            const clone = { ...initialInputs };
                            clone.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                              i
                            ].drawdowns[index].start_year = +dateString;
                            setInitialInputs(clone);
                          }}
                          value={initialInputs.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                            i
                          ].drawdowns[index].start_year.toString()}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={24}
                      xs={24}
                      className="custom-input-fields"
                    >
                      <Form.Item label="End year:">
                        <DateInput
                          onBlur={(date, dateString) => {
                            const clone = { ...initialInputs };
                            clone.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                              i
                            ].drawdowns[index].end_year = +dateString;
                            setInitialInputs(clone);
                          }}
                          value={initialInputs.household_income.savings_and_investments_drawdowns.general_investment_accounts[
                            i
                          ].drawdowns[index].end_year.toString()}
                        />
                      </Form.Item>
                    </Col>
                    <RemoveButton />
                  </Row>
                );
              })}
            </div>
          );
        }
      )}
    </div>
  );
};

export default GeneralInvestmentAccountDrawdown;
