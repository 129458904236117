import React from "react";
import { Row, Col, Form, Typography } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import IAssumptions from "../../../../interfaces/IAssumptions";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;

const Bank = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;
  return (
    <Row>
      <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
        <Form.Item label=" ">
          <Text>Aggregated Bank Accounts</Text>
        </Form.Item>
      </Col>
      <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
        <Form.Item label="Original Balance">
          <MoneyInput
            onBlur={(e) => {
              if (e) {
                const clone = { ...initialInputs };
                clone.assets.bank_accounts.original_balance = +e;
                setInitialInputs(clone);
              } else {
                const clone = { ...initialInputs };
                clone.assets.bank_accounts.original_balance = 0;
                setInitialInputs(clone);
              }
            }}
            value={`${initialInputs.assets.bank_accounts.original_balance}`}
          />
        </Form.Item>
      </Col>
      <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
        <Form.Item label="Min. Balance Acceptable">
          <MoneyInput
            onBlur={(e) => {
              if (e) {
                const clone = { ...initialInputs };
                clone.assets.bank_accounts.minimum_cash_balance_acceptable = +e;
                setInitialInputs(clone);
              } else {
                const clone = { ...initialInputs };
                clone.assets.bank_accounts.minimum_cash_balance_acceptable = 0;
                setInitialInputs(clone);
              }
            }}
            value={`${initialInputs.assets.bank_accounts.minimum_cash_balance_acceptable}`}
          />
        </Form.Item>
      </Col>

      {/* <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
        <Form.Item label="Growth Rate">
          <RateInput
            onBlur={(e) => {
              if (e) {
                const clone = { ...initialAssumption };
                clone.market_data.property_price_inflation.rate = +e;
                setInitialAssumption(clone);
              } else {
                const clone = { ...initialAssumption };
                clone.market_data.property_price_inflation.rate = 0;
                setInitialAssumption(clone);
              }
            }}
            value={`${initialAssumption.market_data.property_price_inflation.rate}`}
          />
        </Form.Item>
      </Col> */}
    </Row>
  );
};

export default Bank;
