import React from "react";
import { Row, Col, Form, Select, InputNumber } from "antd";
import TextInput from "../../controls/TextInput";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";
import DateInput from "../../controls/DateInput";
import RemoveButton from "../RemoveButton";
import { IInputs } from "../../../../interfaces/ISubInputs";
import removeAt from "../../../../helpers/remoteAt";

const { Option } = Select;

const OtherLoan = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {initialInputs.liabilities.other_loans.map((o: any, i: any) => {
        return (
          <Row key={"otherLoans" + i}>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Loan Name">
                <TextInput
                  placeholder="Name"
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans[i].name = e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans[i].name = "";
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.liabilities.other_loans[i].name}
                />
              </Form.Item>
            </Col>
            <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Original Balance">
                <MoneyInput
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans[i].original_balance = +e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans[i].original_balance = 0;
                      setInitialInputs(clone);
                    }
                  }}
                  value={`${initialInputs.liabilities.other_loans[i].original_balance}`}
                />
              </Form.Item>
            </Col>
            <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Interest Rate">
                <RateInput
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans[i].interest_rate = e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans[i].interest_rate = 0;
                      setInitialInputs(clone);
                    }
                  }}
                  value={`${initialInputs.liabilities.other_loans[i].interest_rate}`}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Start Year">
                <DateInput
                  onBlur={(date, dateString) => {
                    const clone = { ...initialInputs };
                    clone.liabilities.other_loans[i].start_year = +dateString;
                    setInitialInputs(clone);
                  }}
                  value={initialInputs.liabilities.other_loans[
                    i
                  ].start_year.toString()}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Loan Period (yrs)">
                <InputNumber className="custom-input-fields" />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="# of Payments/yr">
                <Select
                  defaultValue="12"
                  className="custom-input-fields"
                  value={`${initialInputs.liabilities.other_loans[i].number_of_payments_per_year}`}
                  onChange={(e) => {
                    const clone = { ...initialInputs };
                    clone.liabilities.other_loans[
                      i
                    ].number_of_payments_per_year = +e;
                    setInitialInputs(clone);
                  }}
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="4">4</Option>
                  <Option value="6">6</Option>
                  <Option value="12">12</Option>
                </Select>
              </Form.Item>
            </Col>
            <RemoveButton
              onClick={() => {
                const clone = { ...initialInputs };
                clone.liabilities.other_loans = removeAt(
                  clone.liabilities.other_loans,
                  i
                );
                setInitialInputs(clone);
              }}
            />
          </Row>
        );
      })}
    </div>
  );
};

export default OtherLoan;
