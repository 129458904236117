import React from "react";
import { Row, Col, Form, Typography } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;

const CreditCard = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {" "}
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <Text strong>{initialInputs.liabilities.credit_card.name}</Text>
          </Form.Item>
        </Col>
        <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Original Balance">
            <MoneyInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.liabilities.credit_card.original_balance = +e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.liabilities.credit_card.original_balance = 0;
                  setInitialInputs(clone);
                }
              }}
              value={`${initialInputs.liabilities.credit_card.original_balance}`}
            />
          </Form.Item>
        </Col>
        <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Interest Rate">
            <RateInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.liabilities.credit_card.interest_rate = e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.liabilities.credit_card.interest_rate = 0;
                  setInitialInputs(clone);
                }
              }}
              value={`${initialInputs.liabilities.credit_card.interest_rate}`}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CreditCard;
