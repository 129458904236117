import React from "react";
import { Row, Col, Form, Typography, InputNumber } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";
import IAssumptions from "../../../../interfaces/IAssumptions";

const { Text } = Typography;

const FamilyIncome = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;
  return (
    <div>
      {" "}
      {initialInputs.household_expenses.insurance_policies.family_income_benefit.map(
        (p: any, i: any) => {
          return (
            <Row key={"family-income-benefit" + i}>
              <Col
                lg={6}
                md={6}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text>
                    Family Income Benifit{" "}
                    <Text strong>{initialInputs.household_owners[i].name}</Text>
                  </Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual Expense:">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.insurance_policies.family_income_benefit[
                          i
                        ].annual_expense = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.insurance_policies.family_income_benefit[
                          i
                        ].annual_expense = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.insurance_policies.family_income_benefit[i].annual_expense}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Inflation:">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.insurance_policies.family_income_benefit[
                          i
                        ].inflation = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.insurance_policies.family_income_benefit[
                          i
                        ].inflation = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.insurance_policies.family_income_benefit[i].inflation}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="% After retirement:">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.insurance_policies.family_income_benefit[
                          i
                        ].inflation = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.insurance_policies.family_income_benefit[
                          i
                        ].rate_after_retirement = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.insurance_policies.family_income_benefit[i].rate_after_retirement}`}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Growth Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialAssumption };
                        clone.market_data.retain_price_index.rate = +e;
                        setInitialAssumption(clone);
                      } else {
                        const clone = { ...initialAssumption };
                        clone.market_data.retain_price_index.rate = 0;
                        setInitialAssumption(clone);
                      }
                    }}
                    value={`${initialAssumption.market_data.retain_price_index.rate}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default FamilyIncome;
