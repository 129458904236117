import { Table, Typography } from "antd";
import React from "react";
import IAssumptions from "../../../interfaces/IAssumptions";
import { RootStateOrAny, useSelector } from "react-redux";

// @ts-ignore
import CurrencyFormat from "react-currency-format";
import { Fragment } from "react";
import {
  firstColumnWidth,
  secondColumnWidth,
  thirdColumnWidth,
} from "../AssumptionsCommonVariable";
import { pound } from "../../../components/currencySumbol";
import { numberFormat } from "highcharts";

const { Text } = Typography;

const IncomeLimits = (props: any) => {
  const assumptions: IAssumptions = props.assumptions;

  const Column = [
    {
      title: "Income Limits",
      dataIndex: "name",
      width: firstColumnWidth,
    },
    {
      title: "Threshold",
      dataIndex: "threshold",
      width: secondColumnWidth,
      align: "right" as "right",
      render: (text: any, record: any) => {
        return (
          <Text>
            {pound} {numberFormat(text, 0, ".", ",")}
          </Text>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      width: thirdColumnWidth,
      align: "right" as "right",
      render: (text: any, record: any) => {
        return (
          <Text>
            <CurrencyFormat
              value={text * 100}
              displayType={"text"}
              decimalScale={2}
              fixedDecimalScale={0}
              thousandSeparator={true}
              suffix={"%"}
            />
          </Text>
        );
      },
    },
  ];

  const data = [
    {
      name: "Income Limit for Personal Allowance",
      threshold:
        assumptions.income_limits.income_limit_for_personal_allowance.threshold,
      rate: assumptions.income_limits.income_limit_for_personal_allowance.rate,
      key: "1",
    },
  ];

  return (
    <Fragment>
      <Table
        size={"small"}
        columns={Column}
        dataSource={data}
        pagination={false}
      />
    </Fragment>
  );
};

export default IncomeLimits;
