import { Table } from "antd";
import Column from "antd/lib/table/Column";
import { numberFormat } from "highcharts";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import IForecast from "../../../interfaces/IForecast";

const PersonalPension = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [pension, setPension] = useState([
    ...forecast[0].assets.personal_pension_plans.details.map((s: any, i) => {
      return {
        key: "individual" + i,
        name: s.name,
      };
    }),
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total Pension Plans",
    },
  ]);

  useEffect(() => {
    pension.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(f.assets.personal_pension_plans.details[index].amount),
          0,
          ".",
          ","
        );
      });
    });

    let newTotal: any = total;

    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.assets.personal_pension_plans.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  const data = [...pension, ...total];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default PersonalPension;
