import React, { useState } from "react";
import { Affix, Button, Collapse, Form, Layout } from "antd";
import { RootStateOrAny, useSelector } from "react-redux";
import { IInputs } from "../../interfaces/ISubInputs";
import { v4 as uuid } from "uuid";

import PlanInformation from "./formComponents/PlanInformation";
import AddButton from "./formComponents/AddButton";
import Owner from "./formComponents/people/Onwner";
import Children from "./formComponents/people/Children";
import Property from "./formComponents/assets/Property";
import Bank from "./formComponents/assets/Bank";
import IndividualSavingAccount from "./formComponents/assets/IndividualSavingAccount";
import GeneralInvestmentAccount from "./formComponents/assets/GeneralInvestmentAccount";
import PensionPlan from "./formComponents/assets/PensionPlan";
import Mortgages from "./formComponents/liabilities/Mortgages";
import OtherLoan from "./formComponents/liabilities/OtherLoan";
import CreditCard from "./formComponents/liabilities/CreditCard";
import Employment from "./formComponents/income/Employment";
import SelfEmployment from "./formComponents/income/SelfEmployment";
import Rental from "./formComponents/income/Rental";
import Dividend from "./formComponents/income/Dividend";
import IndividualSavingsAccountDrawdown from "./formComponents/income/IndividualSavingsAccountDrawdown";
import GeneralInvestmentAccountDrawdown from "./formComponents/income/GeneralInvestmentAccountDrawdown";
import DefinedBenefitPension from "./formComponents/income/DefinedBenefitPension";
import DefinedContributionPension from "./formComponents/income/DefinedContributionPension";
import Housing from "./formComponents/expenses/Housing";
import Consumables from "./formComponents/expenses/Consumables";
import Travel from "./formComponents/expenses/Travel";
import Shopping from "./formComponents/expenses/Shopping";
import Entertainment from "./formComponents/expenses/Entertainment";
import Holiday from "./formComponents/expenses/Holiday";
import LifeInsurance from "./formComponents/expenses/LifeInsurance";
import CriticalIllness from "./formComponents/expenses/CriticalIllness";
import FamilyIncome from "./formComponents/expenses/FamilyIncome";
import OneOff from "./formComponents/expenses/OneOff";
import ChildrenEducation from "./formComponents/expenses/ChildrenEducation";

const { Panel } = Collapse;

const NewInputs = () => {
  const activeClient = useSelector(
    (state: RootStateOrAny) => state.activeClientReducer
  );
  const activePlan = useSelector(
    (state: RootStateOrAny) => state.currentInputSetReducer
  );

  const [loading, setLoading] = useState(false);

  const [initialInputs, setInitialInputs] = useState<IInputs>(activePlan);

  const handleFinish = () => {
    console.log(initialInputs);
  };

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Form layout="vertical" size="middle" onFinish={handleFinish}>
        <Collapse>
          <Panel header="Plan Information" key="1">
            <PlanInformation
              input={initialInputs}
              setInput={setInitialInputs}
            />
          </Panel>

          <Panel header="People" key="2">
            <Collapse>
              <Panel
                header="Owner"
                key="2.1"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_owners.push({
                        id: uuid(),
                        name: "",
                        birth_year: 1993,
                        retirement_age: 65,
                      });
                      clone.assets.savings_and_investments.individual_savings_account.push(
                        {
                          original_balance: 0,
                          annual_contribution: 0,
                        }
                      );
                      clone.assets.savings_and_investments.general_investment_account.push(
                        {
                          original_balance: 0,
                          annual_contribution: 0,
                        }
                      );
                      clone.assets.non_employment_defined_contribution_pension_plans.push(
                        {
                          original_balance: 0,
                          annual_contribution: 0,
                        }
                      );
                      clone.household_income.employment_income.push({
                        gross_anual_amount: 0,
                        member_contribution: 0,
                        employer_contribution: 0,
                        inflation: 0,
                        start_year: 2021,
                        end_year: 2090,
                      });
                      clone.household_income.self_employment_income.push({
                        gross_anual_amount: 0,
                      });
                      clone.household_income.rental_income.details.push({
                        share_of_rental_income: 0.5,
                        start_year: 2021,
                      });
                      clone.household_income.dividend_income.push({
                        anual_amount: 0,
                        start_year: 2021,
                        end_year: 2021,
                      });
                      clone.household_income.savings_and_investments_drawdowns.individual_savings_accounts.push(
                        {
                          owner_name: "",
                          drawdowns: [
                            {
                              id: uuid(),
                              name: "Drawdown",
                              amount_to_drawn_down: 0,
                              start_year: 2021,
                              end_year: 2021,
                            },
                          ],
                        }
                      );
                      clone.household_income.savings_and_investments_drawdowns.general_investment_accounts.push(
                        {
                          owner_name: "",
                          drawdowns: [
                            {
                              id: uuid(),
                              name: "Drawdown",
                              amount_to_drawn_down: 0,
                              start_year: 2021,
                              end_year: 2021,
                            },
                          ],
                        }
                      );
                      clone.household_income.pension_income.defined_benifit_pension_plans.push(
                        {
                          option_taken: "Lump Sum",
                          estimated_lump_sum: 0,
                          estimated_annual_pension: 0,
                          annual_increase: 0,
                        }
                      );
                      clone.household_income.pension_income.defined_contribution_pension_plans.push(
                        {
                          option_taken: "Drawdown",
                          drawdown_option_annual_amount: 0,
                        }
                      );
                      clone.household_income.other_income.other_taxable_income.push(
                        {
                          name: "",
                          gross_annual_amount: 0,
                          inflation: 0,
                          start_year: 2021,
                          end_year: 2021,
                        }
                      );
                      clone.household_income.other_income.other_non_taxable_income.push(
                        {
                          name: "",
                          gross_annual_amount: 0,
                          inflation: 0,
                          start_year: 2021,
                          end_year: 2021,
                        }
                      );
                      clone.household_expenses.insurance_policies.life_insurance.push(
                        {
                          name: "Life Insurance - ",
                          annual_expense: 0,
                          inflation: 0,
                          rate_after_retirement: 0,
                        }
                      );
                      clone.household_expenses.insurance_policies.critical_illness_cover.push(
                        {
                          name: "Critical Illness Cover - ",
                          annual_expense: 0,
                          inflation: 0,
                          rate_after_retirement: 0,
                        }
                      );
                      clone.household_expenses.insurance_policies.family_income_benefit.push(
                        {
                          name: "Family Income Benefit - ",
                          annual_expense: 0,
                          inflation: 0,
                          rate_after_retirement: 0,
                        }
                      );

                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Owner input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Children"
                key="2.2"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.children.push({
                        id: uuid(),
                        name: "",
                        birth_year: 2021,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Children input={initialInputs} setInput={setInitialInputs} />
              </Panel>
            </Collapse>
          </Panel>

          <Panel header="Assets" key="3">
            <Collapse>
              <Panel
                header="Properties"
                key="3.1"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.assets.properties.push({
                        id: uuid(),
                        name: "",
                        original_price: 0,
                        start_year: 2021,
                        sell_in_future: true,
                        end_year: 2021,
                        type_of_property: "Main Home",
                        on_mortgage: false,
                        mortgage_rate: 0,
                      });
                      clone.liabilities.mortgages.push({
                        interest_rate: 0,
                        mortgage_period: 0,
                        number_of_payments_per_year: 12,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Property input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Bank Accounts" key="3.2">
                <Bank input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Savings and Investments" key="3.3">
                <Collapse>
                  <Panel header="Individual Saving Account" key="3.31">
                    <IndividualSavingAccount
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel header="General Investment Account" key="3.32">
                    <GeneralInvestmentAccount
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel
                header="Non-Employment Defined Contribution Pension Plans"
                key="3.4"
              >
                <PensionPlan
                  input={initialInputs}
                  setInput={setInitialInputs}
                />
              </Panel>
            </Collapse>
          </Panel>

          <Panel header="Liabilities" key="4">
            <Collapse>
              <Panel header="Mortgages" key="4.1">
                <Mortgages input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Other Loans"
                key="4.2"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.liabilities.other_loans.push({
                        id: uuid(),
                        name: "",
                        original_balance: 0,
                        interest_rate: 0,
                        start_year: 2019,
                        loan_period: 0,
                        number_of_payments_per_year: 12,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <OtherLoan input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Credit Card" key="4.3">
                <CreditCard input={initialInputs} setInput={setInitialInputs} />
              </Panel>
            </Collapse>
          </Panel>

          <Panel header="Income" key="5">
            <Collapse>
              <Panel header="Employment Income" key="5.1">
                <Employment input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Self-Employment Income" key="5.2">
                <SelfEmployment
                  input={initialInputs}
                  setInput={setInitialInputs}
                />
              </Panel>
              <Panel header="Rental Income" key="5.3">
                <Rental input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Dividend Income" key="5.4">
                <Dividend input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Individual Savings Account - Drawdown" key="5.5">
                <Collapse>
                  <Panel header="Individual Savings Account (ISA)" key="5.51">
                    <IndividualSavingsAccountDrawdown
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel header="General Investment Account (GIA)" key="5.52">
                    <GeneralInvestmentAccountDrawdown
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel header="Pension Plans" key="5.6">
                <Collapse>
                  <Panel header="Defined Benefit Pension Plan" key="5.61">
                    <DefinedBenefitPension
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel header="Defined Contribution Pension Plan" key="5.62">
                    <DefinedContributionPension
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel header="Other Income" key="5.7">
                <Collapse>
                  <Panel header="Other Taxable Income" key="5.71">
                    Text
                  </Panel>
                  <Panel header="Other Non-Taxable Income" key="5.72">
                    Text
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
          </Panel>

          <Panel header="Expenses" key="6">
            <Collapse>
              <Panel
                header="Housing"
                key="6.1"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.housing.details.push({
                        id: uuid(),
                        name: "Rent",
                        annual_expense: 0,
                        start_year: 0,
                        end_year: 0,
                        rate_after_retirement: 1,
                        type: "property",
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Housing input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Consumables"
                key="6.2"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.consumables.details.push({
                        id: uuid(),
                        name: "Eating Out",
                        annual_expense: 0,
                        rate_after_retirement: 1,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Consumables
                  input={initialInputs}
                  setInput={setInitialInputs}
                />
              </Panel>
              <Panel
                header="Travel"
                key="6.3"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.travel.details.push({
                        id: uuid(),
                        name: "Travel",
                        annual_expense: 0,
                        rate_after_retirement: 1,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Travel input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Shopping"
                key="6.4"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.shopping.details.push({
                        id: uuid(),
                        name: "Clothing",
                        annual_expense: 0,
                        rate_after_retirement: 1,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Shopping input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Entertainment"
                key="6.5"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.entertainment.details.push({
                        id: uuid(),
                        name: "Drinks",
                        annual_expense: 0,
                        rate_after_retirement: 1,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Entertainment
                  input={initialInputs}
                  setInput={setInitialInputs}
                />
              </Panel>
              <Panel
                header="Holiday"
                key="6.6"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.holiday.details.push({
                        id: uuid(),
                        name: "Flights",
                        annual_expense: 0,
                        rate_after_retirement: 1,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <Holiday input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Insurance Policies" key="6.7">
                <Collapse>
                  <Panel header="Life Insurance" key="6.71">
                    <LifeInsurance
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel header="Critical Illness Cover" key="6.72">
                    <CriticalIllness
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel header="Family Income Benefit" key="6.73">
                    <FamilyIncome
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel
                header="One-Off Expenses"
                key="6.8"
                extra={
                  <AddButton
                    onClick={() => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.one_off_expenses.push({
                        id: uuid(),
                        name: "Renovation",
                        annual_payment_in_todays_terms: 0,
                        inflation: 0,
                        start_year: 2039,
                        end_year: 2041,
                      });
                      setInitialInputs(clone);
                    }}
                  />
                }
              >
                <OneOff input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel header="Children Education Expenses" key="6.9">
                <ChildrenEducation
                  input={initialInputs}
                  setInput={setInitialInputs}
                />
              </Panel>
            </Collapse>
          </Panel>
        </Collapse>
        <Affix
          offsetBottom={50}
          style={{ right: "50px", position: "absolute" }}
        >
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={loading}
          >
            Submit
          </Button>
        </Affix>
      </Form>
    </Layout>
  );
};

export default NewInputs;
