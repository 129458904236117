import { Table } from "antd";
import { numberFormat } from "highcharts";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import IForecast from "../../../interfaces/IForecast";

const SavingAndInvestments = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [savings, setSavings] = useState([
    ...forecast[0].assets.savings_and_investments.individual_savings_accounts.details.map(
      (s: any, i) => {
        return {
          key: "individual" + i,
          name: s.name,
        };
      }
    ),
  ]);
  const [general, setGeneral] = useState([
    ...forecast[0].assets.savings_and_investments.general_investment_accounts.details.map(
      (s: any, i) => {
        return {
          key: "general" + i,
          name: s.name,
        };
      }
    ),
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total Savings and Investments",
    },
  ]);

  useEffect(() => {
    savings.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.assets.savings_and_investments.individual_savings_accounts
              .details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    general.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.assets.savings_and_investments.general_investment_accounts
              .details[index].amount
          ),
          0,
          ".",
          ","
        );
      });
    });
    let newTotal: any = total;

    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.assets.savings_and_investments.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [...savings, ...general, ...total];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default SavingAndInvestments;
