import React from "react";
import { PlusCircleFilled } from "@ant-design/icons";

const AddButton = (props: any) => {
  return (
    <a
      href="#!"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <PlusCircleFilled onClick={(e) => props.onClick(e)} />
    </a>
  );
};

export default AddButton;
