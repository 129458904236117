import { Table, Typography } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../interfaces/IForecast";

const { Title } = Typography;

const Total = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [totalIncome, setTotalIncome] = useState([
    {
      name: "Total Income",
    },
  ]);

  const [totalAdditional, setTotalAdditional] = useState([
    {
      name: "Total Additional Tax charge",
    },
  ]);

  const [totalExpenses, setTotalExpenses] = useState([
    {
      name: "Total Expenses",
    },
  ]);
  const [annualCash, setAnnualCash] = useState([
    {
      name: "Annual Cash inflow/ Outflow",
    },
  ]);

  useEffect(() => {
    let newTotalIncome: any = totalIncome;
    forecast.map((f, i) => {
      newTotalIncome[0]["year" + i] = numberFormat(
        Math.round(f.household_income.total),
        0,
        ".",
        ","
      );
    });
    setTotalIncome(newTotalIncome);

    let newTotalAdditional: any = totalAdditional;
    forecast.map((f, i) => {
      newTotalAdditional[0]["year" + i] = numberFormat(
        Math.round(
          f.household_expenses.additional_tax_charge.total_additional_tax_charge
        ),
        0,
        ".",
        ","
      );
    });
    setTotalAdditional(newTotalAdditional);

    let newTotalExpenses: any = totalExpenses;
    forecast.map((f, i) => {
      newTotalExpenses[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.total_household_expenses),
        0,
        ".",
        ","
      );
    });
    setTotalExpenses(newTotalExpenses);

    let newAnnualCash: any = annualCash;
    forecast.map((f, i) => {
      newAnnualCash[0]["year" + i] = numberFormat(
        Math.round(f.annual_cash_inflow_outflow),
        0,
        ".",
        ","
      );
    });
    setAnnualCash(newAnnualCash);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={[
          ...totalIncome,
          ...totalAdditional,
          ...totalExpenses,
          ...annualCash,
        ]}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default Total;
