const defaultAssumptions = {
  assumptions_set_name:
    "Default UK Assumptions #" + Math.floor(Math.random() * 999),
  client_id: "",
  sdlt_thresholds: {
    c5: {
      threshold: 0,
      taxrate: 0,
    },
    c6: {
      threshold: 125000,
      taxrate: 0.02,
    },
    c7: {
      threshold: 250000,
      taxrate: 0.03,
    },
    c8: {
      threshold: 925000,
      taxrate: 0.1,
    },
    c9: {
      threshold: 1500000,
      taxrate: 0.12,
    },
  },
  isaa: {
    annual_contribution_allowance: {
      allowance: 20000,
      rate: 0,
    },
  },
  pension_contribution_allowance: {
    contribution_annual_allowance: {
      allowance: 40000,
      rate: 0,
    },
    lifetime_allowance: {
      allowance: 1073100,
      rate: 0.025,
    },
    contribution_annual_allowance_floor: {
      allowance: 4000,
      rate: 0,
    },
  },
  pension_contribution_allowance_tapering: {
    threshold_income: {
      threshold: 200000,
      rate: 0,
    },
    lifetime_allowance: {
      threshold: 240000,
      rate: 0.5,
    },
  },
  income_tax_rate_thresholds: {
    personal_allowance: {
      threshold: 0,
      rate: 0,
    },
    basic_rate: {
      threshold: 12500,
      rate: 0.2,
    },
    higher_rate: {
      threshold: 37500,
      rate: 0.4,
    },
    additional_rate: {
      threshold: 150000,
      rate: 0.45,
    },
  },
  income_limits: {
    income_limit_for_personal_allowance: {
      threshold: 100000,
      rate: 0.5,
    },
  },
  employement_minimum_pension_contributions: {
    minimum_contributions: {
      member: 0.05,
      employer: 0.03,
    },
  },
  employment_nic_thresholds: {
    lower_earnings: {
      threshold: 0,
      rate: 0,
    },
    primary_threshold: {
      threshold: 9500,
      rate: 0.12,
    },
    upper_earnings_limit: {
      threshold: 50000,
      rate: 0.02,
    },
  },
  self_employment_nic_class_2_threshold: {
    small_profit_rate: {
      threshold: 6475,
      rate: 158.6,
    },
  },
  self_employment_nic_class_4_threshold: {
    lower_profits_limit: {
      threshold: 9500,
      rate: 0.09,
    },
    upper_earnings_limit: {
      threshold: 50000,
      rate: 0.02,
    },
  },
  dividend_tax_rate_thresholds: {
    personal_allowance: {
      threshold: 0,
      rate: 0,
    },
    basic_rate: {
      threshold: 2000,
      rate: 0.075,
    },
    higher_rate: {
      threshold: 33500,
      rate: 0.325,
    },
    additional_rate: {
      threshold: 150000,
      rate: 0.381,
    },
  },
  residential_property_captical_gains_tax_rate_thresholds: {
    basic_rate: {
      threshold: 12500,
      rate: 0.18,
    },
    higher_and_additional_rate: {
      threshold: 37500,
      rate: 0.28,
    },
  },
  other_assets_capital_gains_tax_rate_thresholds: {
    basic_rate: {
      threshold: 12500,
      rate: 0.1,
    },
    higher_and_additional_rate: {
      threshold: 37500,
      rate: 0.2,
    },
  },
  income_limits_2: {
    capital_gains_tax_annual_exempt_amount: {
      threshold: 12300,
    },
  },
  market_data: {
    property_price_inflation: {
      notes: "",
      rate: 0.03,
    },
    cash_and_money_market_yield: {
      notes: "",
      rate: 0.015,
    },
    savings_and_investment_growth_rate: {
      notes: "",
      rate: 0.05,
    },
    earning_growth_rate: {
      notes: "",
      rate: 0.03,
    },
    retain_price_index: {
      notes: "",
      rate: 0.025,
    },
    consumer_price_index: {
      notes: "",
      rate: 0.025,
    },
    annuity: {
      notes: "",
      rate: 0.048,
    },
    private_school_fee_inflation: {
      notes: "",
      rate: 0.035,
    },
  },
  inputs_assumptions: {
    end_of_forecast_age: 100,
    primary_school_age: 5,
    secondary_school_age: 11,
    university_age: 18,
    graduation_age: 21,
    bank_account_growth_rate: 0,
    credit_card_interest_rate: 0.2,
    state_pension_annual_amount: 8300,
    state_pension_age: 67,
  },
};

export default defaultAssumptions;
