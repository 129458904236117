import { Row, Col, Form, Typography } from "antd";
import React from "react";
import { IInputs } from "../../../../interfaces/ISubInputs";
import DateInput from "../../controls/DateInput";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;

const OtherTaxable = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {initialInputs.household_income.employment_income.map(
        (o: any, i: any) => {
          return (
            <Row key={"employment" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text strong>{initialInputs.household_owners[i].name}</Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Gross annual amount:">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.other_income.other_taxable_income[
                          i
                        ].gross_annual_amount = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.other_income.other_taxable_income[
                          i
                        ].gross_annual_amount = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.other_income.other_taxable_income[i].gross_annual_amount}`}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Inflation">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.other_income.other_taxable_income[
                          i
                        ].inflation = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.other_income.other_taxable_income[
                          i
                        ].inflation = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.other_income.other_taxable_income[i].inflation}`}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Start Year:">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_income.other_income.other_taxable_income[
                        i
                      ].start_year = +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_income.other_income.other_taxable_income[
                      i
                    ].start_year.toString()}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="End Year">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_income.other_income.other_taxable_income[
                        i
                      ].end_year = +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_income.other_income.other_taxable_income[
                      i
                    ].end_year.toString()}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default OtherTaxable;
