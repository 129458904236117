import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useEffect, useState } from "react";
import IForecast from "../../../interfaces/IForecast";

const Property = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [properties, setProperties] = useState([
    ...forecast[0].assets.properties.map((p, i) => {
      return {
        key: i,
        name: p.name,
      };
    }),
  ]);

  useEffect(() => {
    const clone: any = properties;
    clone.map((p: any, index: any) => {
      forecast.map((f, i) => {
        p["year" + i] = numberFormat(
          Math.round(f.assets.properties[index].amount),
          0,
          ".",
          ","
        );
      });
    });
    setProperties(clone);
  }, [forecast]);

  const Colums: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [...properties];
  return (
    <div>
      <Table
        columns={Colums}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default Property;
