import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useEffect, useState } from "react";
import IForecast from "../../../interfaces/IForecast";

const BankAccounts = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [aggregated, setAggregated] = useState([
    {
      name: "Aggregated Bank",
    },
  ]);

  useEffect(() => {
    let aggre: any = {
      key: "1",
      name: "Aggregated Bank Accounts",
    };
    forecast.map((f, i) => {
      aggre["year" + i] = numberFormat(
        Math.round(f.assets.bank_account.amount),
        0,
        ".",
        ","
      );
    });

    setAggregated(aggre);
  }, [forecast]);

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  const data = [aggregated];

  return (
    <div>
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default BankAccounts;
