import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs } from "antd";
import IForecast from "../../../../interfaces/IForecast";
import { numberFormat } from "highcharts";

const { Title } = Typography;
const { TabPane } = Tabs;

const PensionAnnual = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [owner, setOwner] = useState([
    ...forecast[0].ages.owner_ages.map((o, i) => {
      return {
        key: "owner" + i,
        name: o.name,
      };
    }),
  ]);

  const [thresholdIncome, setThresholdIncome] = useState(
    owner.map((o, i) => {
      return {
        key: "thresholdIncome" + i,
        name: "Threshold Income",
      };
    })
  );

  const [ExceedsTapering, setExceedsTapering] = useState(
    owner.map((o, i) => {
      return {
        key: "exceedT" + i,
        name: "Exceeds Tapering Threshold?",
      };
    })
  );

  const [adjustedIncome, setAdjustedIncome] = useState(
    owner.map((o, i) => {
      return {
        key: "adjustedI" + i,
        name: "Adjusted Income",
      };
    })
  );

  const [ExceedsTapering2, setExceedsTapering2] = useState(
    owner.map((o, i) => {
      return {
        key: "exceedTap" + i,
        name: "Exceeds Tapering Threshold?",
      };
    })
  );

  const [pensionContribution, setPensionContribution] = useState(
    owner.map((o, i) => {
      return {
        key: "pensionContrib" + i,
        name: "Pension Contribution Annual Allowance",
      };
    })
  );

  const [totalGross, setTotalGross] = useState(
    owner.map((o, i) => {
      return {
        key: "totalGross" + i,
        name: "Total Gross Pension Contribution",
      };
    })
  );

  useEffect(() => {
    owner.map((o, index) => {
      let newThresholdIncome: any = [...thresholdIncome];
      forecast.map((f, i) => {
        newThresholdIncome[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .pension_annual_allowance_tapering_analysis.threshold_income
          ),
          0,
          ".",
          ","
        );
      });
      setThresholdIncome(newThresholdIncome);

      let newExceedsTapering: any = [...ExceedsTapering];
      forecast.map((f, i) => {
        newExceedsTapering[index]["year" + i] = f.household_expenses
          .additional_tax_charge.details[index]
          .pension_annual_allowance_tapering_analysis.exceeds_tapering_threshold
          ? "Yes"
          : "No";
      });
      setExceedsTapering(newExceedsTapering);

      let newAdjustedIncome: any = [...adjustedIncome];
      forecast.map((f, i) => {
        newAdjustedIncome[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .pension_annual_allowance_tapering_analysis.adjusted_income
          ),
          0,
          ".",
          ","
        );
      });
      setAdjustedIncome(newAdjustedIncome);

      let newExceedsTapering2: any = [...ExceedsTapering2];
      forecast.map((f, i) => {
        newExceedsTapering2[index]["year" + i] = f.household_expenses
          .additional_tax_charge.details[index]
          .pension_annual_allowance_tapering_analysis
          .exceeds_tapering_threshold_2
          ? "Yes"
          : "No";
      });
      setExceedsTapering2(newExceedsTapering2);

      let newPensionContribution: any = [...pensionContribution];
      forecast.map((f, i) => {
        newPensionContribution[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .pension_annual_allowance_tapering_analysis
              .pension_contribution_annual_allowance
          ),
          0,
          ".",
          ","
        );
      });
      setPensionContribution(newPensionContribution);

      let newTotalGross: any = [...totalGross];
      forecast.map((f, i) => {
        newTotalGross[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .pension_annual_allowance_tapering_analysis
              .total_gross_pension_contribution
          ),
          0,
          ".",
          ","
        );
      });
      setTotalGross(newTotalGross);
    });
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" type="card">
        {owner.map((o, i) => {
          return (
            <TabPane tab={o.name} key={i}>
              <Table
                columns={Columns}
                dataSource={[
                  thresholdIncome[i],
                  ExceedsTapering[i],
                  adjustedIncome[i],
                  ExceedsTapering2[i],
                  pensionContribution[i],
                  totalGross[i],
                ]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default PensionAnnual;
