import { Table } from "antd";
import { numberFormat } from "highcharts";
import React from "react";
import { useEffect, useState } from "react";
import IForecast from "../../../../interfaces/IForecast";

const OtherIncome = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [taxable, setTaxable] = useState([
    ...forecast[0].household_income.other_income.other_taxable_income.details.map(
      (s: any, i) => {
        return {
          key: "individual" + i,
          name: s.name,
        };
      }
    ),
  ]);
  const [nonTaxable, setNonTaxable] = useState([
    ...forecast[0].household_income.other_income.other_non_taxable_income.details.map(
      (s: any, i) => {
        return {
          key: "general" + i,
          name: s.name,
        };
      }
    ),
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total Other Income",
    },
  ]);

  useEffect(() => {
    taxable.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_income.other_income.other_taxable_income.details[index]
              .amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    nonTaxable.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_income.other_income.other_non_taxable_income.details[
              index
            ].amount
          ),
          0,
          ".",
          ","
        );
      });
    });
    let newTotal: any = total;

    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.household_income.other_income.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [
    {
      key: "1",
      name: "Other Taxable",
    },
    ...taxable,
    {
      key: "2",
      name: "Other Non Taxable",
    },
    ...nonTaxable,
    ...total,
  ];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default OtherIncome;
