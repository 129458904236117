import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../../interfaces/IForecast";

const SDLTCharge = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [sdlt, setSdlt] = useState([
    ...forecast[0].household_expenses.additional_tax_charge.sdlt_charge.details.map(
      (m: any, i) => {
        return {
          key: "income" + i,
          name: m.name,
        };
      }
    ),
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total SDLT Charge",
    },
  ]);

  useEffect(() => {
    sdlt.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.sdlt_charge.details[
              index
            ].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    let newTotal: any = total;

    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(
          f.household_expenses.additional_tax_charge.sdlt_charge.total
        ),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);
  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [...sdlt, ...total];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default SDLTCharge;
