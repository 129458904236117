import React from "react";
import { Row, Col, Form, Typography, Select, InputNumber } from "antd";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;
const { Option } = Select;

const Mortgages = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {initialInputs.liabilities.mortgages.map((p: any, i: any) => {
        if (initialInputs.assets.properties[i].on_mortgage) {
          return (
            <Row key={"mortgages" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text strong>{initialInputs.assets.properties[i].name}</Text>
                </Form.Item>
              </Col>
              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Mortgage Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.assets.properties[i].mortgage_rate = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.assets.properties[i].mortgage_rate = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.assets.properties[i].mortgage_rate}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Interest Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.liabilities.mortgages[i].interest_rate = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.liabilities.mortgages[i].interest_rate = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.liabilities.mortgages[i].interest_rate}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Mortgage Period (yrs)">
                  <InputNumber
                    className="custom-input-fields"
                    onBlur={(e) => {
                      const clone = { ...initialInputs };
                      clone.liabilities.mortgages[i].mortgage_period =
                        +e.target.value;
                      setInitialInputs(clone);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="# of Annual Payments">
                  <Select
                    defaultValue="12"
                    className="custom-input-fields"
                    value={`${initialInputs.liabilities.mortgages[i].number_of_payments_per_year}`}
                    onChange={(e) => {
                      const clone = { ...initialInputs };
                      clone.liabilities.mortgages[
                        i
                      ].number_of_payments_per_year = +e;
                      setInitialInputs(clone);
                    }}
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="4">4</Option>
                    <Option value="6">6</Option>
                    <Option value="12">12</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual Payments">
                  <Text strong>$ 123123</Text>
                </Form.Item>
              </Col> */}
            </Row>
          );
        } else {
          return "";
        }
      })}
    </div>
  );
};

export default Mortgages;
