import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../../interfaces/IForecast";

const CreditCardReq = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [bankBalanceStart, setBankBalanceStart] = useState([
    {
      name: "Bank Balance of Start of Year",
    },
  ]);
  const [minimumBalance, setMinimunBalance] = useState([
    {
      name: "Minimum Balance Acceptable",
    },
  ]);
  const [excess, setExcess] = useState([
    {
      name: "Excess Cash At Start of Year",
    },
  ]);
  const [cashInflow, setCashInflow] = useState([
    {
      name: "Total Cash Inflow/(Outflow)",
    },
  ]);
  const [cashAvailable, setCashAvailable] = useState([
    {
      name: "Cash Available/(Required)",
    },
  ]);

  useEffect(() => {
    let newbankBalanceStart: any = {
      key: "1",
      name: "Bank Balance of Start of Year",
    };
    let newMin: any = {
      key: "2",
      name: "Minimum Balance Acceptable",
    };
    let newExcess: any = {
      key: "3",
      name: "Excess Cash At Start of Year",
    };
    let newCashInflow: any = {
      key: "4",
      name: "Total Cash Inflow/(Outflow)",
    };
    let newCashAvailable: any = {
      key: "5",
      name: "Cash Available/(Required)",
    };

    forecast.map((f, i) => {
      newbankBalanceStart["year" + i] = numberFormat(
        Math.round(
          f.creditors.credit_card_requirement_analysis.balance_at_start_of_year
        ),
        0,
        ".",
        ","
      );
    });

    forecast.map((f, i) => {
      newMin["year" + i] = numberFormat(
        Math.round(
          f.creditors.credit_card_requirement_analysis
            .minimum_balance_acceptable
        ),
        0,
        ".",
        ","
      );
    });

    forecast.map((f, i) => {
      newExcess["year" + i] = numberFormat(
        Math.round(
          f.creditors.credit_card_requirement_analysis
            .excess_cash_at_start_of_year
        ),
        0,
        ".",
        ","
      );
    });

    forecast.map((f, i) => {
      newCashInflow["year" + i] = numberFormat(
        Math.round(
          f.creditors.credit_card_requirement_analysis.total_cash_inflow_outflow
        ),
        0,
        ".",
        ","
      );
    });

    forecast.map((f, i) => {
      newCashAvailable["year" + i] = numberFormat(
        Math.round(f.creditors.credit_card_requirement_analysis.cash_available),
        0,
        ".",
        ","
      );
    });

    setBankBalanceStart(newbankBalanceStart);
    setMinimunBalance(newMin);
    setExcess(newExcess);
    setCashInflow(newCashInflow);
    setCashAvailable(newCashAvailable);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  const data = [
    bankBalanceStart,
    minimumBalance,

    excess,
    cashInflow,
    cashAvailable,
  ];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default CreditCardReq;
