import React from "react";
import { Row, Col, Form, Typography } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import IAssumptions from "../../../../interfaces/IAssumptions";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;
const SelfEmployment = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;
  return (
    <div>
      {" "}
      {initialInputs.household_income.self_employment_income.map(
        (s: any, i: any) => {
          return (
            <Row key={"selfEmployment" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text strong>{initialInputs.household_owners[i].name} </Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Gross annual amount:">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.self_employment_income[
                          i
                        ].gross_anual_amount = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.self_employment_income[
                          i
                        ].gross_anual_amount = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.self_employment_income[i].gross_anual_amount}`}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Growth Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialAssumption };
                        clone.market_data.consumer_price_index.rate = +e;
                        setInitialAssumption(clone);
                      } else {
                        const clone = { ...initialAssumption };
                        clone.market_data.consumer_price_index.rate = 0;
                        setInitialAssumption(clone);
                      }
                    }}
                    value={`${initialAssumption.market_data.consumer_price_index.rate}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default SelfEmployment;
