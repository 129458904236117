import { Table } from "antd";
import { numberFormat } from "highcharts";

import { useEffect, useState } from "react";
import IForecast from "../../../../interfaces/IForecast";

const OtherLoans = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [otherLoans, setOthserLoans] = useState([
    ...forecast[0].creditors.other_loans.details.map((m: any, i) => {
      return {
        key: "individual" + i,
        name: m.name,
      };
    }),
  ]);

  const [total, setTotal] = useState([
    {
      name: "Total Other Loans",
    },
  ]);

  useEffect(() => {
    otherLoans.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(f.creditors.other_loans.details[index].amount),
          0,
          ".",
          ","
        );
      });
    });

    let newTotal: any = total;

    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.creditors.other_loans.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  const data = [...otherLoans, ...total];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default OtherLoans;
