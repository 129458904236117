import React from "react";
import { Row, Col, Form, Typography, Select, Divider } from "antd";
import TextInput from "../../controls/TextInput";
import MoneyInput from "../../controls/MoneyInput";
import DateInput from "../../controls/DateInput";
import RemoveButton from "../RemoveButton";
import { IInputs } from "../../../../interfaces/ISubInputs";
import removeAt from "../../../../helpers/remoteAt";
import RateInput from "../../controls/RateInput";
import IAssumptions from "../../../../interfaces/IAssumptions";
import { numberFormat } from "highcharts";
import { pound } from "../../../../components/currencySumbol";

const { Text } = Typography;
const { Option } = Select;

const Property = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;

  function calcPresentValue(property: any, currentYear: number) {
    const pv =
      property.original_price /
      (1 + property.growth_rate) ** (property.start_year - currentYear);
    return pv;
  }
  function calcFutureValue(property: any, currentYear: number) {
    const fv =
      property.original_price /
      (1 + property.growth_rate) ** (currentYear - property.start_year);
    return fv;
  }

  const calcTodaysValue = (property: any, currentYear: number) => {
    const { start_year } = property;

    if (start_year < currentYear) {
      return calcFutureValue(property, currentYear);
    } else if (start_year > currentYear) {
      return calcPresentValue(property, currentYear);
    } else {
      return property.original_price;
    }
  };
  return (
    <div>
      {initialInputs.assets.properties.map((p: any, i: any) => {
        return (
          <Row key={"properties" + i}>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Property Name">
                <TextInput
                  placeholder="Name"
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.assets.properties[i].name = e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.assets.properties[i].name = "";
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.assets.properties[i].name}
                />
              </Form.Item>
            </Col>

            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Original Price">
                <MoneyInput
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialInputs };
                      clone.assets.properties[i].original_price = +e;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.assets.properties[i].original_price = 0;
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.assets.properties[
                    i
                  ].original_price.toString()}
                />
              </Form.Item>
            </Col>

            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Today's Value">
                <Text strong>
                  {pound}
                  {numberFormat(
                    calcTodaysValue(p, initialInputs.current_year),
                    0,
                    ".",
                    ","
                  )}
                </Text>
              </Form.Item>
            </Col>

            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Growth Rate">
                <RateInput
                  onBlur={(e) => {
                    if (e) {
                      const clone = { ...initialAssumption };
                      clone.market_data.property_price_inflation.rate = +e;
                      setInitialAssumption(clone);
                    } else {
                      const clone = { ...initialAssumption };
                      clone.market_data.property_price_inflation.rate = 0;
                      setInitialAssumption(clone);
                    }
                  }}
                  value={`${initialAssumption.market_data.property_price_inflation.rate}`}
                />
              </Form.Item>
            </Col>

            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Start Year">
                <DateInput
                  onBlur={(date, dateString) => {
                    if (dateString) {
                      const clone = { ...initialInputs };
                      clone.assets.properties[i].start_year = +dateString;
                      setInitialInputs(clone);
                    } else {
                      const clone = { ...initialInputs };
                      clone.assets.properties[i].start_year = 2025;
                      setInitialInputs(clone);
                    }
                  }}
                  value={initialInputs.assets.properties[
                    i
                  ].start_year.toString()}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Sell in future?">
                <Select
                  defaultValue="no"
                  className="custom-input-fields"
                  value={
                    initialInputs.assets.properties[i].sell_in_future
                      ? "yes"
                      : "no"
                  }
                  onChange={(e) => {
                    const clone = { ...initialInputs };
                    clone.assets.properties[i].sell_in_future =
                      e === "no" ? false : true;
                    setInitialInputs(clone);
                  }}
                  style={{ width: "95%" }}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Sell/ End Year">
                <DateInput
                  onBlur={(date, dateString) => {
                    const clone = { ...initialInputs };
                    clone.assets.properties[i].end_year = +dateString;
                    setInitialInputs(clone);
                  }}
                  value={initialInputs.assets.properties[i].end_year.toString()}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="Type of Property">
                <Select
                  defaultValue="Main Home"
                  className="custom-input-fields"
                  value={initialInputs.assets.properties[i].type_of_property}
                  onChange={(e) => {
                    const clone = { ...initialInputs };
                    clone.assets.properties[i].type_of_property = e;
                    setInitialInputs(clone);
                  }}
                  style={{ width: "95%" }}
                >
                  <Option value="Main Home">Main Home</Option>
                  <Option value="Other Residential">Other Residential</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
              <Form.Item label="On Mortgage?">
                <Select
                  defaultValue="no"
                  className="custom-input-fields"
                  value={
                    initialInputs.assets.properties[i].on_mortgage
                      ? "yes"
                      : "no"
                  }
                  onChange={(e) => {
                    const clone = { ...initialInputs };
                    clone.assets.properties[i].on_mortgage =
                      e === "no" ? false : true;
                    setInitialInputs(clone);
                  }}
                  style={{ width: "95%" }}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <RemoveButton
              onClick={() => {
                const clone = { ...initialInputs };
                clone.assets.properties = removeAt(clone.assets.properties, i);
                setInitialInputs(clone);
              }}
            />
            <Divider />
          </Row>
        );
      })}
    </div>
  );
};

export default Property;
