import React from "react";

import { Form, Col, Row } from "antd";
import TextInput from "../controls/TextInput";
import DateInput from "../controls/DateInput";

const PlanInformation = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;

  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Plan Name">
            <TextInput
              placeholder="Plan Name"
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.input_set_name = e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.input_set_name = "";
                  setInitialInputs(clone);
                }
              }}
              value={initialInputs.input_set_name}
            />
          </Form.Item>
        </Col>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Start Year">
            <DateInput
              onBlur={(date, dateString) => {
                if (dateString) {
                  const clone = { ...initialInputs };
                  clone.current_year = +dateString;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.current_year = 2024;
                  setInitialInputs(clone);
                }
              }}
              value={initialInputs.current_year.toString()}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PlanInformation;
