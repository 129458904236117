import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs } from "antd";
import IForecast from "../../../../interfaces/IForecast";
import { numberFormat } from "highcharts";

const { Title } = Typography;
const { TabPane } = Tabs;

const AdditionalTax = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [owner, setOwner] = useState([
    ...forecast[0].ages.owner_ages.map((o, i) => {
      return {
        key: "owner" + i,
        name: o.name,
      };
    }),
  ]);

  const [gross, setGross] = useState(
    owner.map((o, i) => {
      return {
        key: "gross" + i,
        name: "Gross Salary",
      };
    })
  );

  const [memberPension, setMemberPension] = useState(
    owner.map((o, i) => {
      return {
        key: "memberPension" + i,
        name: "Member's Pension Contribution",
      };
    })
  );

  const [netGross, setNetGross] = useState(
    owner.map((o, i) => {
      return {
        key: "netGross" + i,
        name: "Net Gross Salary",
      };
    })
  );

  const [selfEmployment, setSelfEmployment] = useState(
    owner.map((o, i) => {
      return {
        key: "selfEmployment" + i,
        name: "Self Employment Income",
      };
    })
  );

  const [rental, setRental] = useState(
    owner.map((o, i) => {
      return {
        key: "rental" + i,
        name: "Rental Income",
      };
    })
  );

  const [taxablePension, setTaxablePension] = useState(
    owner.map((o, i) => {
      return {
        key: "tPension" + i,
        name: "Taxable Pension Income",
      };
    })
  );

  const [otherIncome, setOtherIncome] = useState(
    owner.map((o, i) => {
      return {
        key: "otherIncome" + i,
        name: "Other Taxable Income",
      };
    })
  );

  const [pensionPlan, setPensionPlan] = useState(
    owner.map((o, i) => {
      return {
        key: "pensionPlan" + i,
        name: "Pension Plan",
      };
    })
  );

  const [priorYear, setPriorYear] = useState(
    owner.map((o, i) => {
      return {
        key: "priorYear" + i,
        name: "Prior Year Excess Pension Contribution",
      };
    })
  );

  const [tTaxableIncome, setTTaxableIncome] = useState(
    owner.map((o, i) => {
      return {
        key: "tTaxableIncome" + i,
        name: "Total Taxable Income Excluding Dividends",
      };
    })
  );

  const [dividendIncome, setDividendIncome] = useState(
    owner.map((o, i) => {
      return {
        key: "dividendIncome" + i,
        name: "Dividend Income",
      };
    })
  );

  const [totalTaxable, setTotalTaxable] = useState(
    owner.map((o, i) => {
      return {
        key: "totalTaxable" + i,
        name: "Total Taxable Income",
      };
    })
  );

  const [taxChargeNonDividend, setTaxChargeNonDividend] = useState(
    owner.map((o, i) => {
      return {
        key: "incomeTaxChargeNon" + i,
        name: "Income Tax Charge on Non Dividend Income",
      };
    })
  );

  const [taxChargeDividend, setTaxChargeDividend] = useState(
    owner.map((o, i) => {
      return {
        key: "incomeTaxCharge" + i,
        name: "Income Tax Charge on Dividend Income",
      };
    })
  );

  const [limitOnPersonal, setLimitOnPersonal] = useState(
    owner.map((o, i) => {
      return {
        key: "limitOnPersonal" + i,
        name: "Limit on Personal Allowance",
      };
    })
  );

  const [nicClass2, setNicClass2] = useState(
    owner.map((o, i) => {
      return {
        key: "nicClass2" + i,
        name: "NIC Class 2 Charge",
      };
    })
  );

  const [nicClass4, setNicClass4] = useState(
    owner.map((o, i) => {
      return {
        key: "nicClass4" + i,
        name: "NIC Class 4 Charge",
      };
    })
  );

  const [taxCreditReceived, setTaxCreditReceived] = useState(
    owner.map((o, i) => {
      return {
        key: "taxCreditReceived" + i,
        name: "Tax Credit Received Through Pension",
      };
    })
  );

  const [capitalGainsProperty, setCapitalGainsProperty] = useState(
    owner.map((o, i) => {
      return {
        key: "capitalGainsProperty" + i,
        name: "Capital Gains Tax - Residential Property",
      };
    })
  );

  const [capitalGainsOther, setCapitalGainsOther] = useState(
    owner.map((o, i) => {
      return {
        key: "capitalGainsOther" + i,
        name: "Capital Gains Tax - Other Assets",
      };
    })
  );

  const [taxDeducted, setTaxDeducted] = useState(
    owner.map((o, i) => {
      return {
        key: "taxDeducted" + i,
        name: "Tax Deducted at Source",
      };
    })
  );

  const [additionalTax, setAdditionalTax] = useState(
    owner.map((o, i) => {
      return {
        key: "additionalTax" + i,
        name: "Additional Tax (Charge)/ Credit",
      };
    })
  );

  useEffect(() => {
    owner.map((o, index) => {
      let newGross: any = [...gross];
      forecast.map((f, i) => {
        newGross[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .gross_salary
          ),
          0,
          ".",
          ","
        );
      });
      setGross(newGross);

      let newMemberPension: any = [...memberPension];
      forecast.map((f, i) => {
        newMemberPension[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .member_pension_contribution
          ),
          0,
          ".",
          ","
        );
      });
      setMemberPension(newMemberPension);

      let newNetGross: any = [...netGross];
      forecast.map((f, i) => {
        newNetGross[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_gross_salary_after_less
          ),
          0,
          ".",
          ","
        );
      });
      setNetGross(newNetGross);

      let newSelfEmployment: any = [...selfEmployment];
      forecast.map((f, i) => {
        newSelfEmployment[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .self_employment_income
          ),
          0,
          ".",
          ","
        );
      });
      setSelfEmployment(newSelfEmployment);

      let newRental: any = [...rental];
      forecast.map((f, i) => {
        newRental[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .rental_income
          ),
          0,
          ".",
          ","
        );
      });
      setRental(newRental);

      let newTaxablePension: any = [...taxablePension];
      forecast.map((f, i) => {
        newTaxablePension[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .taxable_pension_income
          ),
          0,
          ".",
          ","
        );
      });
      setTaxablePension(newTaxablePension);

      let newOtherIncome: any = [...otherIncome];
      forecast.map((f, i) => {
        newOtherIncome[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .other_taxable_income
          ),
          0,
          ".",
          ","
        );
      });
      setOtherIncome(newOtherIncome);

      let newPensionPlan: any = [...pensionPlan];
      forecast.map((f, i) => {
        newPensionPlan[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .pension_plan
          ),
          0,
          ".",
          ","
        );
      });
      setPensionPlan(newPensionPlan);

      let newPriorYear: any = [...priorYear];
      forecast.map((f, i) => {
        newPriorYear[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .prior_year_excess_pension_contribution
          ),
          0,
          ".",
          ","
        );
      });
      setPriorYear(newPriorYear);

      let newTTaxableIncome: any = [...tTaxableIncome];
      forecast.map((f, i) => {
        newTTaxableIncome[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_taxable_income_excluding_dividends
          ),
          0,
          ".",
          ","
        );
      });
      setTTaxableIncome(newTTaxableIncome);

      let newDividendIncome: any = [...dividendIncome];
      forecast.map((f, i) => {
        newDividendIncome[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .dividend_income
          ),
          0,
          ".",
          ","
        );
      });
      setDividendIncome(newDividendIncome);

      let newTotalTable: any = [...totalTaxable];
      forecast.map((f, i) => {
        newTotalTable[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .total_taxable_income
          ),
          0,
          ".",
          ","
        );
      });
      setTotalTaxable(newTotalTable);

      let newTaxChargeNonDividend: any = [...taxChargeNonDividend];
      forecast.map((f, i) => {
        newTaxChargeNonDividend[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .income_tax_charge_on_non_dividend_income
          ),
          0,
          ".",
          ","
        );
      });
      setTaxChargeNonDividend(newTaxChargeNonDividend);

      let newTaxChargeDividend: any = [...taxChargeDividend];
      forecast.map((f, i) => {
        newTaxChargeDividend[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .income_tax_charge_on_dividend_income
          ),
          0,
          ".",
          ","
        );
      });
      setTaxChargeDividend(newTaxChargeDividend);

      let newLimitOnPersonal: any = [...limitOnPersonal];
      forecast.map((f, i) => {
        newLimitOnPersonal[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .limit_on_personal_allowance
          ),
          0,
          ".",
          ","
        );
      });
      setLimitOnPersonal(newLimitOnPersonal);

      let newNicClass2: any = [...nicClass2];
      forecast.map((f, i) => {
        newNicClass2[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .nic_class_2_charge
          ),
          0,
          ".",
          ","
        );
      });
      setNicClass2(newNicClass2);

      let newNicClass4: any = [...nicClass4];
      forecast.map((f, i) => {
        newNicClass4[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .nic_class_4_charge
          ),
          0,
          ".",
          ","
        );
      });
      setNicClass4(newNicClass4);

      let newTaxCreditReceived: any = [...taxCreditReceived];
      forecast.map((f, i) => {
        newTaxCreditReceived[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .tax_credit_received_through_pension
          ),
          0,
          ".",
          ","
        );
      });
      setTaxCreditReceived(newTaxCreditReceived);

      let newCapitalGainsProperty: any = [...capitalGainsProperty];
      forecast.map((f, i) => {
        newCapitalGainsProperty[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains_tax_residential_property
          ),
          0,
          ".",
          ","
        );
      });
      setCapitalGainsProperty(newCapitalGainsProperty);

      let newCapitalGainsOther: any = [...capitalGainsOther];
      forecast.map((f, i) => {
        newCapitalGainsOther[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .capital_gains_tax_other_assets
          ),
          0,
          ".",
          ","
        );
      });
      setCapitalGainsOther(newCapitalGainsOther);

      let newTaxDeducted: any = [...taxDeducted];
      forecast.map((f, i) => {
        newTaxDeducted[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .tax_deducted_at_source
          ),
          0,
          ".",
          ","
        );
      });
      setTaxDeducted(newTaxDeducted);

      let newAdditionalTax: any = [...additionalTax];
      forecast.map((f, i) => {
        newAdditionalTax[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.additional_tax_charge.details[index]
              .additional_tax
          ),
          0,
          ".",
          ","
        );
      });
      setAdditionalTax(newAdditionalTax);
    });
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" type="card">
        {owner.map((o, i) => {
          return (
            <TabPane tab={o.name} key={i}>
              <Table
                columns={Columns}
                dataSource={[
                  gross[i],
                  memberPension[i],
                  netGross[i],
                  selfEmployment[i],
                  rental[i],
                  taxablePension[i],
                  otherIncome[i],
                  pensionPlan[i],
                  priorYear[i],
                  tTaxableIncome[i],
                  dividendIncome[i],
                  totalTaxable[i],
                  taxChargeNonDividend[i],
                  taxChargeDividend[i],
                  limitOnPersonal[i],
                  nicClass2[i],
                  nicClass4[i],
                  taxCreditReceived[i],
                  capitalGainsProperty[i],
                  capitalGainsOther[i],
                  taxDeducted[i],
                  additionalTax[i],
                ]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default AdditionalTax;
