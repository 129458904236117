import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs } from "antd";
import IForecast from "../../../../interfaces/IForecast";
import { numberFormat } from "highcharts";

const { Title } = Typography;
const { TabPane } = Tabs;

const ChildrenEducation = (props: any) => {
  const forecast: IForecast[] = props.forecast;
  const [children, setChildren] = useState([
    ...forecast[0].ages.children_ages.map((o, i) => {
      return {
        key: "childre" + i,
        name: o.name,
      };
    }),
  ]);

  const [primary, setPrimary] = useState(
    children.map((o, i) => {
      return {
        key: "primary" + i,
        name: "Primary School Fees",
      };
    })
  );
  const [secondary, setSenondary] = useState(
    children.map((o, i) => {
      return {
        key: "secondary" + i,
        name: "Secondary School Fees",
      };
    })
  );
  const [university, setUniversity] = useState(
    children.map((o, i) => {
      return {
        key: "university" + i,
        name: "University Fees",
      };
    })
  );
  const [pTotal, setPTotal] = useState(
    children.map((o, i) => {
      return {
        key: "pTotal" + i,
        name: "Personal Total",
      };
    })
  );

  const [total, setTotal] = useState([
    {
      name: "Total Chidren Education",
    },
  ]);

  useEffect(() => {
    children.map((c, index) => {
      let newPrimary: any = [...primary];
      forecast.map((f, i) => {
        newPrimary[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.children_education_expenses.details[index]
              .primary_school_fees
          ),
          0,
          ".",
          ","
        );
      });
      setPrimary(newPrimary);

      let newScondary: any = [...secondary];
      forecast.map((f, i) => {
        newScondary[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.children_education_expenses.details[index]
              .secondary_school_fees
          ),
          0,
          ".",
          ","
        );
      });
      setSenondary(newScondary);
      let newUniversity: any = [...university];
      forecast.map((f, i) => {
        newUniversity[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.children_education_expenses.details[index]
              .university_fees
          ),
          0,
          ".",
          ","
        );
      });
      setUniversity(newUniversity);
      let newPTotal: any = [...pTotal];
      forecast.map((f, i) => {
        newPTotal[index]["year" + i] = numberFormat(
          Math.round(
            f.household_expenses.children_education_expenses.details[index]
              .total
          ),
          0,
          ".",
          ","
        );
      });
      setPTotal(newPTotal);
    });

    let newTotal: any = total;
    forecast.map((f, i) => {
      newTotal[0]["year" + i] = numberFormat(
        Math.round(f.household_expenses.children_education_expenses.total),
        0,
        ".",
        ","
      );
    });
    setTotal(newTotal);
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" type="card">
        {children.map((o, i) => {
          return (
            <TabPane tab={o.name}>
              <Table
                columns={Columns}
                dataSource={[
                  primary[i],
                  secondary[i],
                  university[i],
                  pTotal[i],
                ]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          );
        })}
        <TabPane tab="Total Children Education" key="11">
          <Table
            columns={Columns}
            dataSource={[...total]}
            scroll={{ x: true }}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ChildrenEducation;
