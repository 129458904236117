import React from "react";
import { Row, Col, Form, Typography } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import IAssumptions from "../../../../interfaces/IAssumptions";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;

const ChildrenEducation = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;
  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <Text>Primary School Fee</Text>
          </Form.Item>
        </Col>
        <Col lg={5} md={5} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Annual Fees">
            <MoneyInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.household_expenses.children_education_expenses.primary_school_fees.annual_fee_in_todays_terms =
                    +e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.household_expenses.children_education_expenses.primary_school_fees.annual_fee_in_todays_terms = 0;
                  setInitialInputs(clone);
                }
              }}
              value={`${initialInputs.household_expenses.children_education_expenses.primary_school_fees.annual_fee_in_todays_terms}`}
            />
          </Form.Item>
        </Col>

        <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Growth Rate">
            <RateInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialAssumption };
                  clone.market_data.private_school_fee_inflation.rate = +e;
                  setInitialAssumption(clone);
                } else {
                  const clone = { ...initialAssumption };
                  clone.market_data.private_school_fee_inflation.rate = 0;
                  setInitialAssumption(clone);
                }
              }}
              value={`${initialAssumption.market_data.private_school_fee_inflation.rate}`}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <Text>Seconday School Fee</Text>
          </Form.Item>
        </Col>
        <Col lg={5} md={5} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Annual Fees">
            <MoneyInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.household_expenses.children_education_expenses.seconday_school_fees.annual_fee_in_todays_terms =
                    +e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.household_expenses.children_education_expenses.seconday_school_fees.annual_fee_in_todays_terms = 0;
                  setInitialInputs(clone);
                }
              }}
              value={`${initialInputs.household_expenses.children_education_expenses.seconday_school_fees.annual_fee_in_todays_terms}`}
            />
          </Form.Item>
        </Col>

        <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Growth Rate">
            <RateInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialAssumption };
                  clone.market_data.private_school_fee_inflation.rate = +e;
                  setInitialAssumption(clone);
                } else {
                  const clone = { ...initialAssumption };
                  clone.market_data.private_school_fee_inflation.rate = 0;
                  setInitialAssumption(clone);
                }
              }}
              value={`${initialAssumption.market_data.private_school_fee_inflation.rate}`}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label=" ">
            <Text>University Fee</Text>
          </Form.Item>
        </Col>
        <Col lg={5} md={5} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Annual Fees">
            <MoneyInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialInputs };
                  clone.household_expenses.children_education_expenses.university_fees.annual_fee_in_todays_terms =
                    +e;
                  setInitialInputs(clone);
                } else {
                  const clone = { ...initialInputs };
                  clone.household_expenses.children_education_expenses.university_fees.annual_fee_in_todays_terms = 0;
                  setInitialInputs(clone);
                }
              }}
              value={`${initialInputs.household_expenses.children_education_expenses.university_fees.annual_fee_in_todays_terms}`}
            />
          </Form.Item>
        </Col>

        <Col lg={3} md={3} sm={24} xs={24} className="custom-input-fields">
          <Form.Item label="Growth Rate">
            <RateInput
              onBlur={(e) => {
                if (e) {
                  const clone = { ...initialAssumption };
                  clone.market_data.consumer_price_index.rate = +e;
                  setInitialAssumption(clone);
                } else {
                  const clone = { ...initialAssumption };
                  clone.market_data.consumer_price_index.rate = 0;
                  setInitialAssumption(clone);
                }
              }}
              value={`${initialAssumption.market_data.consumer_price_index.rate}`}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ChildrenEducation;
