import React from "react";
import { Row, Col, Form, Typography, InputNumber } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";
import DateInput from "../../controls/DateInput";
import TextInput from "../../controls/TextInput";
import RemoveButton from "../RemoveButton";
import { IInputs } from "../../../../interfaces/ISubInputs";
import removeAt from "../../../../helpers/remoteAt";

const { Text } = Typography;

const OneOff = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {" "}
      {initialInputs.household_expenses.one_off_expenses.map(
        (e: any, i: any) => {
          return (
            <Row key={"oneOffExpenses" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Expense:">
                  <TextInput
                    placeholder="Name"
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses[i].name = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses[i].name = "";
                        setInitialInputs(clone);
                      }
                    }}
                    value={
                      initialInputs.household_expenses.one_off_expenses[i].name
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                lg={5}
                md={5}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual expense">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses[
                          i
                        ].annual_payment_in_todays_terms = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses[
                          i
                        ].annual_payment_in_todays_terms = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.one_off_expenses[i].annual_payment_in_todays_terms}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Inflation:">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses[i].inflation =
                          e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses[
                          i
                        ].inflation = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_expenses.one_off_expenses[i].inflation}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Start year:">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.one_off_expenses[i].start_year =
                        +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_expenses.one_off_expenses[
                      i
                    ].start_year.toString()}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="End year:">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_expenses.one_off_expenses[i].end_year =
                        +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_expenses.one_off_expenses[
                      i
                    ].end_year.toString()}
                  />
                </Form.Item>
              </Col>
              <RemoveButton
                onClick={() => {
                  const clone = { ...initialInputs };
                  clone.household_expenses.one_off_expenses = removeAt(
                    clone.household_expenses.one_off_expenses,
                    i
                  );
                  setInitialInputs(clone);
                }}
              />
            </Row>
          );
        }
      )}
    </div>
  );
};

export default OneOff;
