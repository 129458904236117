import { Table, Card, Button, Tabs, Typography } from "antd";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import IForecast from "../../interfaces/IForecast";
import { forecastRoute } from "../../routes/apiRoutes";
import Property from "./forecastComponents/Property";
import BankAccounts from "./forecastComponents/BankAccounts";
import SavingAndInvestments from "./forecastComponents/SavingAndInvestments";
import PersonalPension from "./forecastComponents/PersonalPension";
import Mortgages from "./forecastComponents/liabilities/Mortgages";
import OtherLoans from "./forecastComponents/liabilities/OtherLoans";
import CreditCard from "./forecastComponents/liabilities/CreditCard";
import CreditCardReq from "./forecastComponents/liabilities/CreditCardReq";
import Employment from "./forecastComponents/income/Employment";
import SelfEmployment from "./forecastComponents/income/SelfEmployment";
import Rental from "./forecastComponents/income/Rental";
import EmployersPension from "./forecastComponents/income/EmployersPension";
import Dividend from "./forecastComponents/income/Dividend";
import SavingsAndInvestment from "./forecastComponents/income/SavingsAndInvestment";
import PropertySale from "./forecastComponents/income/PropertySale";
import OtherIncome from "./forecastComponents/income/OtherIncome";
import Housing from "./forecastComponents/Expenses/Housing";
import Consumables from "./forecastComponents/Expenses/Consumables";
import Travel from "./forecastComponents/Expenses/Travel";
import Shopping from "./forecastComponents/Expenses/Shopping";
import Entertainment from "./forecastComponents/Expenses/Entertainment";
import Holiday from "./forecastComponents/Expenses/Holiday";
import OneOff from "./forecastComponents/Expenses/OneOff";
import Financial from "./forecastComponents/Expenses/Financial";
import Pension from "./forecastComponents/income/Pension";
import ChildrenEducation from "./forecastComponents/Expenses/ChildrenEducation";
import AdditionalTax from "./forecastComponents/tax/AdditionalTax";
import PensionAnnual from "./forecastComponents/tax/PensionAnnual";
import CapitalGains from "./forecastComponents/tax/CapitalGains";
import TotalGains from "./forecastComponents/tax/TotalGains";
import SDLTCharge from "./forecastComponents/tax/SDLTCharge";
import Total from "./forecastComponents/Total";

const { TabPane } = Tabs;
const { Title } = Typography;

const Forecast = () => {
  const activeInput = useSelector(
    (state: RootStateOrAny) => state.currentInputSetReducer
  );

  const [forecast, setForecast] = useState<IForecast>();

  const [hasForecast, setHasForecast] = useState(false);

  return (
    <Fragment>
      <Card
        bordered={false}
        extra={
          !hasForecast && (
            <Button
              type={"primary"}
              onClick={async () => {
                const res = await axios.get(forecastRoute + activeInput._id);
                setForecast(res.data);
                setHasForecast(true);
                console.log(res.data);
              }}
            >
              Get Forecast Details
            </Button>
          )
        }
      >
        {hasForecast && (
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="Assets" key="1">
              <Tabs defaultActiveKey="1.1" type="card">
                <TabPane tab="Property" key="1.1">
                  <Property forecast={forecast} />
                </TabPane>
                <TabPane tab="Bank Accounts" key="1.2">
                  <BankAccounts forecast={forecast} />
                </TabPane>
                <TabPane tab="Savings and Investments" key="1.3">
                  <SavingAndInvestments forecast={forecast} />
                </TabPane>
                <TabPane tab="Personal Pension Plan" key="1.4">
                  <PersonalPension forecast={forecast} />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Liabilities" key="2">
              <Tabs defaultActiveKey="2.1" type="card">
                <TabPane tab="Mortgages" key="2.1">
                  <Mortgages forecast={forecast} />
                </TabPane>
                <TabPane tab="Other Loans" key="2.2">
                  <OtherLoans forecast={forecast} />
                </TabPane>

                <TabPane tab="Credit Card" key="2.3">
                  <CreditCard forecast={forecast} />
                </TabPane>
                <TabPane tab="Credit Card Requirement Analysis" key="2.4">
                  <CreditCardReq forecast={forecast} />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Income" key="3">
              <Tabs defaultActiveKey="3.1" type="card">
                <TabPane tab="Employment" key="3.1">
                  <Employment forecast={forecast} />
                </TabPane>
                <TabPane tab="Employer's Pension Contribution" key="3.2">
                  <EmployersPension forecast={forecast} />
                </TabPane>
                <TabPane tab="Self-Employment" key="3.3">
                  <SelfEmployment forecast={forecast} />
                </TabPane>
                <TabPane tab="Rental" key="3.4">
                  <Rental forecast={forecast} />
                </TabPane>
                <TabPane tab="Dividend" key="3.5">
                  <Dividend forecast={forecast} />
                </TabPane>
                <TabPane tab="Savings and Investments" key="3.7">
                  <SavingsAndInvestment forecast={forecast} />
                </TabPane>
                <TabPane tab="Pension" key="3.8">
                  <Pension forecast={forecast} />
                </TabPane>
                <TabPane tab="Residential Property Sale" key="3.9">
                  <PropertySale forecast={forecast} />
                </TabPane>
                <TabPane tab="Other" key="3.10">
                  <OtherIncome forecast={forecast} />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Expenses" key="4">
              <Tabs defaultActiveKey="4.1" type="card">
                <TabPane tab="Housing" key="4.1">
                  <Housing forecast={forecast} />
                </TabPane>
                <TabPane tab="Consumables" key="4.2">
                  <Consumables forecast={forecast} />
                </TabPane>
                <TabPane tab="Travel" key="4.3">
                  <Travel forecast={forecast} />
                </TabPane>
                <TabPane tab="Shopping" key="4.4">
                  <Shopping forecast={forecast} />
                </TabPane>
                <TabPane tab="Entertainment" key="4.5">
                  <Entertainment forecast={forecast} />
                </TabPane>
                <TabPane tab="Holiday" key="4.6">
                  <Holiday forecast={forecast} />
                </TabPane>
                <TabPane tab="One-Off" key="4.7">
                  <OneOff forecast={forecast} />
                </TabPane>
                <TabPane tab="Children Education" key="4.8">
                  <ChildrenEducation forecast={forecast} />
                </TabPane>
                <TabPane tab="Financial" key="4.9">
                  <Financial forecast={forecast} />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Tax" key="5">
              <Tabs defaultActiveKey="5.1" type="card">
                <TabPane tab="Additional Tax Charge" key="5.1">
                  <AdditionalTax forecast={forecast} />
                </TabPane>
                <TabPane
                  tab="Pension Annual Allowance Tapering Analysis"
                  key="5.2"
                >
                  <PensionAnnual forecast={forecast} />
                </TabPane>
                <TabPane tab="Capital Gains" key="5.3">
                  <CapitalGains forecast={forecast} />
                </TabPane>
                <TabPane tab="Total Gains from Other Assets" key="5.4">
                  <TotalGains forecast={forecast} />
                </TabPane>
                <TabPane tab="SDLT Charge" key="5.5">
                  <SDLTCharge forecast={forecast} />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Total" key="6">
              <Total forecast={forecast} />
            </TabPane>
          </Tabs>
        )}
      </Card>
    </Fragment>
  );
};

export default Forecast;
