import React, { useEffect, useMemo, useState } from "react";
import {
  Affix,
  Badge,
  Button,
  Collapse,
  Form,
  Layout,
  Tag,
  Popover,
} from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { IInputs } from "../../interfaces/ISubInputs";
import { v4 as uuid } from "uuid";

import PlanInformation from "./formComponents/PlanInformation";
import AddButton from "./formComponents/AddButton";
import Owner from "./formComponents/people/Onwner";
import Children from "./formComponents/people/Children";
import Property from "./formComponents/assets/Property";
import Bank from "./formComponents/assets/Bank";
import IndividualSavingAccount from "./formComponents/assets/IndividualSavingAccount";
import GeneralInvestmentAccount from "./formComponents/assets/GeneralInvestmentAccount";
import PensionPlan from "./formComponents/assets/PensionPlan";
import Mortgages from "./formComponents/liabilities/Mortgages";
import OtherLoan from "./formComponents/liabilities/OtherLoan";
import CreditCard from "./formComponents/liabilities/CreditCard";
import Employment from "./formComponents/income/Employment";
import SelfEmployment from "./formComponents/income/SelfEmployment";
import Rental from "./formComponents/income/Rental";
import Dividend from "./formComponents/income/Dividend";
import IndividualSavingsAccountDrawdown from "./formComponents/income/IndividualSavingsAccountDrawdown";
import GeneralInvestmentAccountDrawdown from "./formComponents/income/GeneralInvestmentAccountDrawdown";
import DefinedBenefitPension from "./formComponents/income/DefinedBenefitPension";
import DefinedContributionPension from "./formComponents/income/DefinedContributionPension";
import OtherTaxable from "./formComponents/income/OtherTaxable";
import OtherNonTaxable from "./formComponents/income/OtherNonTaxable";
import Housing from "./formComponents/expenses/Housing";
import Consumables from "./formComponents/expenses/Consumables";
import Travel from "./formComponents/expenses/Travel";
import Shopping from "./formComponents/expenses/Shopping";
import Entertainment from "./formComponents/expenses/Entertainment";
import Holiday from "./formComponents/expenses/Holiday";
import LifeInsurance from "./formComponents/expenses/LifeInsurance";
import CriticalIllness from "./formComponents/expenses/CriticalIllness";
import FamilyIncome from "./formComponents/expenses/FamilyIncome";
import OneOff from "./formComponents/expenses/OneOff";
import ChildrenEducation from "./formComponents/expenses/ChildrenEducation";
import axios from "axios";
import {
  assummptionsRoute,
  inputsRoute,
  summaryRoute,
} from "../../routes/apiRoutes";
import {
  GetInputsAction,
  setCurrentInputSetAction,
} from "../../redux/inputs/inputs";
import { useHistory } from "react-router-dom";
import { setSummaryAction } from "../../redux/summary/summary";
import IAssumptions from "../../interfaces/IAssumptions";
import { setRealSummaryAction } from "../../redux/summary/realSummary";
import CalcRealSummary from "../../helpers/calcRealSummary";
import { getAssumptionsAction } from "../../redux/assumptions/assumptions";
import StatePension from "./formComponents/income/StatePension";
import { numberFormat } from "highcharts";
import { pound } from "../../components/currencySumbol";

const { Panel } = Collapse;

const NewInputs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const client = useSelector(
    (state: RootStateOrAny) => state.activeClientReducer
  );
  const activePlan = useSelector(
    (state: RootStateOrAny) => state.currentInputSetReducer
  );

  const [loading, setLoading] = useState(false);

  const [initialInputs, setInitialInputs] = useState<IInputs>(activePlan);

  const assumptions: IAssumptions[] = useSelector(
    (state: RootStateOrAny) => state.assumptionReducer
  );
  const [initialAssumption, setInitialAssumption] = useState<IAssumptions>(
    assumptions[0]
  );

  const propertyTotal = useMemo(() => {
    return initialInputs.assets.properties.reduce((a: any, p: any) => {
      return a + p.original_price;
    }, 0);
  }, [initialInputs]);

  const bankTotal = useMemo(() => {
    return initialInputs.assets.bank_accounts.original_balance;
  }, [initialInputs]);

  const isaTotal = useMemo(() => {
    return initialInputs.assets.savings_and_investments.individual_savings_account.reduce(
      (a, b) => {
        return a + b.original_balance;
      },
      0
    );
  }, [initialInputs]);

  const giaTotal = useMemo(() => {
    return initialInputs.assets.savings_and_investments.general_investment_account.reduce(
      (a, b) => {
        return a + b.original_balance;
      },
      0
    );
  }, [initialInputs]);

  const saiTotal = useMemo(() => {
    return isaTotal + giaTotal;
  }, [initialInputs]);

  const pensionTotal = useMemo(() => {
    return initialInputs.assets.non_employment_defined_contribution_pension_plans.reduce(
      (a: any, p: any) => {
        return a + p.original_balance;
      },
      0
    );
  }, [initialInputs]);

  const assetsTotal = useMemo(() => {
    return propertyTotal + bankTotal + saiTotal;
  }, [initialInputs]);

  const otherLoanTotal = useMemo(() => {
    return initialInputs.liabilities.other_loans.reduce((a, b) => {
      return a + b.original_balance;
    }, 0);
  }, [initialInputs]);

  const creditCard = useMemo(() => {
    return initialInputs.liabilities.credit_card.original_balance;
  }, [initialInputs]);

  const liabilitiesTotal = useMemo(() => {
    return otherLoanTotal + creditCard;
  }, [initialInputs]);

  const employmentTotal = useMemo(() => {
    return initialInputs.household_income.employment_income.reduce((a, b) => {
      return a + b.gross_anual_amount;
    }, 0);
  }, [initialInputs]);

  const selfEmploymentTotal = useMemo(() => {
    return initialInputs.household_income.self_employment_income.reduce(
      (a, b) => {
        return a + b.gross_anual_amount;
      },
      0
    );
  }, [initialInputs]);

  const rentalTotal = useMemo(() => {
    return initialInputs.household_income.rental_income
      .joint_annual_rental_income;
  }, [initialInputs]);

  const isaDrawdownTotal = useMemo(() => {
    let isaTotal = 0;
    initialInputs.household_income.savings_and_investments_drawdowns.individual_savings_accounts.map(
      (d) => {
        isaTotal += d.drawdowns.reduce((a, b) => {
          return a + b.amount_to_drawn_down;
        }, 0);
      }
    );
    return isaTotal;
  }, [initialInputs]);

  const giaDrawdownTotal = useMemo(() => {
    let isaTotal = 0;
    initialInputs.household_income.savings_and_investments_drawdowns.general_investment_accounts.map(
      (d) => {
        isaTotal += d.drawdowns.reduce((a, b) => {
          return a + b.amount_to_drawn_down;
        }, 0);
      }
    );
    return isaTotal;
  }, [initialInputs]);

  const saiDrawdownTotal = useMemo(() => {
    return isaDrawdownTotal + giaDrawdownTotal;
  }, [initialInputs]);

  const statePensionTotal = useMemo(() => {
    return initialInputs.household_income.pension_income.state_pension.reduce(
      (a, b) => {
        return a + b.annual_amount;
      },
      0
    );
  }, [initialInputs]);

  const definedBenefitTotal = useMemo(() => {
    return initialInputs.household_income.pension_income.defined_benifit_pension_plans.reduce(
      (a, b) => {
        return a + b.estimated_lump_sum;
      },
      0
    );
  }, [initialInputs]);

  const definedContributionTotal = useMemo(() => {
    return initialInputs.household_income.pension_income.defined_contribution_pension_plans.reduce(
      (a, b) => {
        return a + b.drawdown_option_annual_amount;
      },
      0
    );
  }, [initialInputs]);

  const pensionIncomeTotal = useMemo(() => {
    return statePensionTotal + definedBenefitTotal + definedContributionTotal;
  }, [initialInputs]);

  const taxableIncomeTotal = useMemo(() => {
    return initialInputs.household_income.other_income.other_taxable_income.reduce(
      (a, b) => {
        return a + b.gross_annual_amount;
      },
      0
    );
  }, [initialInputs]);
  const nonTaxableIncomeTotal = useMemo(() => {
    return initialInputs.household_income.other_income.other_non_taxable_income.reduce(
      (a, b) => {
        return a + b.gross_annual_amount;
      },
      0
    );
  }, [initialInputs]);

  const otherIncomeTotal = useMemo(() => {
    return taxableIncomeTotal + nonTaxableIncomeTotal;
  }, [initialInputs]);

  const incomeTotal = useMemo(() => {
    return (
      employmentTotal +
      selfEmploymentTotal +
      rentalTotal +
      saiDrawdownTotal +
      pensionIncomeTotal +
      otherLoanTotal
    );
  }, [initialInputs]);

  const housingTotal = useMemo(() => {
    return initialInputs.household_expenses.housing.details.reduce((a, b) => {
      return a + b.annual_expense;
    }, 0);
  }, [initialInputs]);
  const consumableTotal = useMemo(() => {
    return initialInputs.household_expenses.consumables.details.reduce(
      (a, b) => {
        return a + b.annual_expense;
      },
      0
    );
  }, [initialInputs]);
  const travelTotal = useMemo(() => {
    return initialInputs.household_expenses.travel.details.reduce((a, b) => {
      return a + b.annual_expense;
    }, 0);
  }, [initialInputs]);
  const shoppingTotal = useMemo(() => {
    return initialInputs.household_expenses.shopping.details.reduce((a, b) => {
      return a + b.annual_expense;
    }, 0);
  }, [initialInputs]);
  const entertainmentTotal = useMemo(() => {
    return initialInputs.household_expenses.entertainment.details.reduce(
      (a, b) => {
        return a + b.annual_expense;
      },
      0
    );
  }, [initialInputs]);
  const holidayTotal = useMemo(() => {
    return initialInputs.household_expenses.holiday.details.reduce((a, b) => {
      return a + b.annual_expense;
    }, 0);
  }, [initialInputs]);
  const lifeInsuranceTotal = useMemo(() => {
    return initialInputs.household_expenses.insurance_policies.life_insurance.reduce(
      (a, b) => {
        return a + b.annual_expense;
      },
      0
    );
  }, [initialInputs]);
  const criticalIllnessTotal = useMemo(() => {
    return initialInputs.household_expenses.insurance_policies.critical_illness_cover.reduce(
      (a, b) => {
        return a + b.annual_expense;
      },
      0
    );
  }, [initialInputs]);
  const familyIncomeTotal = useMemo(() => {
    return initialInputs.household_expenses.insurance_policies.family_income_benefit.reduce(
      (a, b) => {
        return a + b.annual_expense;
      },
      0
    );
  }, [initialInputs]);

  const insuranceTotal = useMemo(() => {
    return lifeInsuranceTotal + criticalIllnessTotal + familyIncomeTotal;
  }, [initialInputs]);

  const oneOffTotal = useMemo(() => {
    return initialInputs.household_expenses.one_off_expenses.reduce((a, b) => {
      return a + b.annual_payment_in_todays_terms;
    }, 0);
  }, [initialInputs]);

  const childrenEducationTotal = useMemo(() => {
    return (
      initialInputs.household_expenses.children_education_expenses
        .primary_school_fees.annual_fee_in_todays_terms +
      initialInputs.household_expenses.children_education_expenses
        .seconday_school_fees.annual_fee_in_todays_terms +
      initialInputs.household_expenses.children_education_expenses
        .university_fees.annual_fee_in_todays_terms
    );
  }, [initialInputs]);

  const expenseTotal = useMemo(() => {
    return (
      housingTotal +
      consumableTotal +
      travelTotal +
      shoppingTotal +
      entertainmentTotal +
      holidayTotal +
      insuranceTotal +
      oneOffTotal +
      childrenEducationTotal
    );
  }, [initialInputs]);

  const handleFinish = async () => {
    try {
      setLoading(true);

      await axios.patch(assummptionsRoute, initialAssumption);

      await dispatch(getAssumptionsAction(client._id));

      const res = await axios.patch(inputsRoute + client._id, initialInputs);

      await dispatch(GetInputsAction(client._id));
      await dispatch(setCurrentInputSetAction(res.data));

      const summary = await axios.get(summaryRoute + res.data._id);

      await dispatch(setSummaryAction(summary.data));

      const activeAssumptionSet = assumptions.filter((a) => {
        return a._id === res.data.assumptions_set_id;
      });

      await dispatch(
        setRealSummaryAction(
          CalcRealSummary(summary.data, activeAssumptionSet[0])
        )
      );

      history.push("/dashboard/clientDashboard/clientplandetails");
      setLoading(false);
    } catch (err: any) {
      console.log(err.response.data);
    }
  };

  return (
    <Layout
      style={{
        backgroundColor: "white",
        marginBottom: "100px",
        height: "100%",
      }}
    >
      <Form layout="vertical" size="middle" onFinish={handleFinish}>
        <Collapse>
          <Panel header="Plan Information" key="1">
            <PlanInformation
              input={initialInputs}
              setInput={setInitialInputs}
            />
          </Panel>

          <Panel header="People" key="2">
            <Collapse>
              <Panel
                header="Owner"
                key="2.1"
                extra={
                  <>
                    <Badge
                      showZero
                      count={initialInputs.household_owners.length}
                      style={{ backgroundColor: "gray", marginRight: "16px" }}
                    />
                    {initialInputs.household_owners.length < 2 ? (
                      <AddButton
                        onClick={() => {
                          const clone = { ...initialInputs };

                          clone.household_owners.push({
                            id: uuid(),
                            name: "John Doe",
                            birth_year: 1993,
                            retirement_age: 65,
                          });
                          clone.assets.savings_and_investments.individual_savings_account.push(
                            {
                              original_balance: 0,
                              annual_contribution: 0,
                            }
                          );
                          clone.assets.savings_and_investments.general_investment_account.push(
                            {
                              original_balance: 0,
                              annual_contribution: 0,
                            }
                          );
                          clone.assets.non_employment_defined_contribution_pension_plans.push(
                            {
                              original_balance: 0,
                              annual_contribution: 0,
                            }
                          );
                          clone.household_income.employment_income.push({
                            gross_anual_amount: 0,
                            member_contribution: 0,
                            employer_contribution: 0,
                            inflation: 0,
                            start_year: 20202,
                            end_year: 2090,
                          });
                          clone.household_income.self_employment_income.push({
                            gross_anual_amount: 0,
                          });
                          clone.household_income.rental_income.details.push({
                            share_of_rental_income: 0.5,
                            start_year: 2021,
                          });
                          clone.household_income.dividend_income.push({
                            anual_amount: 0,
                            start_year: 2021,
                            end_year: 2021,
                          });

                          clone.household_income.savings_and_investments_drawdowns.individual_savings_accounts.push(
                            {
                              owner_name: "",
                              drawdowns: [
                                {
                                  id: uuid(),
                                  name: "Drawdown",
                                  amount_to_drawn_down: 0,
                                  start_year: 2021,
                                  end_year: 2021,
                                },
                              ],
                            }
                          );
                          clone.household_income.savings_and_investments_drawdowns.general_investment_accounts.push(
                            {
                              owner_name: "",
                              drawdowns: [
                                {
                                  id: uuid(),
                                  name: "Drawdown",
                                  amount_to_drawn_down: 0,
                                  start_year: 2021,
                                  end_year: 2021,
                                },
                              ],
                            }
                          );
                          clone.household_income.pension_income.defined_benifit_pension_plans.push(
                            {
                              option_taken: "Lump Sum",
                              estimated_lump_sum: 0,
                              estimated_annual_pension: 0,
                              annual_increase: 0,
                            }
                          );
                          clone.household_income.pension_income.defined_contribution_pension_plans.push(
                            {
                              option_taken: "Drawdown",
                              drawdown_option_annual_amount: 0,
                            }
                          );
                          clone.household_income.other_income.other_taxable_income.push(
                            {
                              name: "",
                              gross_annual_amount: 0,
                              inflation: 0,
                              start_year: 2021,
                              end_year: 2021,
                            }
                          );
                          clone.household_income.other_income.other_non_taxable_income.push(
                            {
                              name: "",
                              gross_annual_amount: 0,
                              inflation: 0,
                              start_year: 2021,
                              end_year: 2021,
                            }
                          );
                          clone.household_expenses.insurance_policies.life_insurance.push(
                            {
                              name: "Life Insurance - ",
                              annual_expense: 0,
                              inflation: 0,
                              rate_after_retirement: 0,
                            }
                          );
                          clone.household_expenses.insurance_policies.critical_illness_cover.push(
                            {
                              name: "Critical Illness Cover - ",
                              annual_expense: 0,
                              inflation: 0,
                              rate_after_retirement: 0,
                            }
                          );
                          clone.household_expenses.insurance_policies.family_income_benefit.push(
                            {
                              name: "Family Income Benefit - ",
                              annual_expense: 0,
                              inflation: 0,
                              rate_after_retirement: 0,
                            }
                          );

                          setInitialInputs(clone);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </>
                }
              >
                <Owner input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Children"
                key="2.2"
                extra={
                  <>
                    <Badge
                      showZero
                      count={initialInputs.children.length}
                      style={{ backgroundColor: "gray", marginRight: "16px" }}
                    />
                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.children.push({
                          id: uuid(),
                          name: "",
                          birth_year: 2021,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Children input={initialInputs} setInput={setInitialInputs} />
              </Panel>
            </Collapse>
          </Panel>

          <Panel
            header="Assets"
            key="3"
            extra={
              <Popover content="Total">
                <Tag color="#4fc3f7">
                  {pound + numberFormat(assetsTotal, 0, ".", ",")}
                </Tag>
              </Popover>
            }
          >
            <Collapse>
              <Panel
                header="Properties"
                key="3.1"
                extra={
                  <>
                    <Badge
                      showZero
                      count={initialInputs.assets.properties.length}
                      style={{
                        backgroundColor: "#4fc3f7",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="blue">
                        {pound + numberFormat(propertyTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.assets.properties.push({
                          id: uuid(),
                          name: "",
                          original_price: 0,
                          start_year: 2021,
                          sell_in_future: true,
                          end_year: 2021,
                          type_of_property: "Main Home",
                          on_mortgage: false,
                          mortgage_rate: 0,
                        });
                        clone.liabilities.mortgages.push({
                          interest_rate: 0,
                          mortgage_period: 0,
                          number_of_payments_per_year: 12,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Property
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Bank Accounts"
                key="3.2"
                extra={
                  <Popover content="Total">
                    <Tag color="blue">
                      {pound + numberFormat(bankTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Bank
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Savings and Investments"
                key="3.3"
                extra={
                  <Popover content="Total">
                    <Tag color="blue">
                      {pound + numberFormat(saiTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Collapse>
                  <Panel
                    header="Individual Saving Account"
                    key="3.31"
                    extra={
                      <Popover content="Total">
                        <Tag color="blue">
                          {pound + numberFormat(isaTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <IndividualSavingAccount
                      input={initialInputs}
                      setInput={setInitialInputs}
                      assumption={initialAssumption}
                      setAssumption={setInitialAssumption}
                    />
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel
                    header="General Investment Account"
                    key="3.32"
                    extra={
                      <Popover content="Total">
                        <Tag color="blue">
                          {pound + numberFormat(giaTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <GeneralInvestmentAccount
                      input={initialInputs}
                      setInput={setInitialInputs}
                      assumption={initialAssumption}
                      setAssumption={setInitialAssumption}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel
                header="Non-Employment Defined Contribution Pension Plans"
                key="3.4"
                extra={
                  <Popover content="Total">
                    <Tag color="blue">
                      {pound + numberFormat(pensionTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <PensionPlan
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
            </Collapse>
          </Panel>

          <Panel
            header="Liabilities"
            key="4"
            extra={
              <Popover content="Total">
                <Tag color="#9575cd">
                  {pound + numberFormat(liabilitiesTotal, 0, ".", ",")}
                </Tag>
              </Popover>
            }
          >
            <Collapse>
              <Panel
                header="Mortgages"
                key="4.1"
                extra={
                  <Badge
                    showZero
                    count={initialInputs.liabilities.mortgages.length}
                    style={{ backgroundColor: "#9575cd", marginRight: "16px" }}
                  />
                }
              >
                <Mortgages input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Other Loans"
                key="4.2"
                extra={
                  <>
                    <Badge
                      showZero
                      count={initialInputs.liabilities.other_loans.length}
                      style={{
                        backgroundColor: "#9575cd",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="purple">
                        {pound + numberFormat(otherLoanTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.liabilities.other_loans.push({
                          id: uuid(),
                          name: "",
                          original_balance: 0,
                          interest_rate: 0,
                          start_year: 2019,
                          loan_period: 0,
                          number_of_payments_per_year: 12,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <OtherLoan input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Credit Card"
                key="4.3"
                extra={
                  <Popover content="Total">
                    <Tag color="purple">
                      {pound + numberFormat(creditCard, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <CreditCard input={initialInputs} setInput={setInitialInputs} />
              </Panel>
            </Collapse>
          </Panel>

          <Panel
            header="Income"
            key="5"
            extra={
              <Popover content="Total">
                <Tag color="#81c784">
                  {pound + numberFormat(incomeTotal, 0, ".", ",")}
                </Tag>
              </Popover>
            }
          >
            <Collapse>
              <Panel
                header="Employment Income"
                key="5.1"
                extra={
                  <Popover content="Total">
                    <Tag color="green">
                      {pound + numberFormat(employmentTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Employment
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Self-Employment Income"
                key="5.2"
                extra={
                  <Popover content="Total">
                    <Tag color="green">
                      {pound + numberFormat(selfEmploymentTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <SelfEmployment
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Rental Income"
                key="5.3"
                extra={
                  <Popover content="Total">
                    <Tag color="green">
                      {pound + numberFormat(rentalTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Rental
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              {/* <Panel header="Dividend Income" key="5.4">
                <Dividend input={initialInputs} setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption} />
              </Panel> */}
              <Panel
                header="Saving and Investment- Drawdown"
                key="5.5"
                extra={
                  <Popover content="Total">
                    <Tag color="green">
                      {pound + numberFormat(saiDrawdownTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Collapse>
                  <Panel
                    header="Individual Savings Account (ISA)"
                    key="5.51"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound + numberFormat(isaDrawdownTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <IndividualSavingsAccountDrawdown
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel
                    header="General Investment Account (GIA)"
                    key="5.52"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound + numberFormat(giaDrawdownTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <GeneralInvestmentAccountDrawdown
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel
                header="Pension Plans"
                key="5.6"
                extra={
                  <Popover content="Total">
                    <Tag color="green">
                      {pound + numberFormat(pensionIncomeTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Collapse>
                  <Panel
                    header="State Pension"
                    key="5.61"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound + numberFormat(statePensionTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <StatePension
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel
                    header="Defined Benefit Pension Plan"
                    key="5.62"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound +
                            numberFormat(definedBenefitTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <DefinedBenefitPension
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel
                    header="Defined Contribution Pension Plan"
                    key="5.63"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound +
                            numberFormat(definedContributionTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <DefinedContributionPension
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel
                header="Other Income"
                key="5.7"
                extra={
                  <Popover content="Total">
                    <Tag color="green">
                      {pound + numberFormat(otherIncomeTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Collapse>
                  <Panel
                    header="Other Taxable Income"
                    key="5.71"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound +
                            numberFormat(taxableIncomeTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <OtherTaxable
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                  <Panel
                    header="Other Non-Taxable Income"
                    key="5.72"
                    extra={
                      <Popover content="Total">
                        <Tag color="green">
                          {pound +
                            numberFormat(nonTaxableIncomeTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <OtherNonTaxable
                      input={initialInputs}
                      setInput={setInitialInputs}
                    />
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
          </Panel>

          <Panel
            header="Expenses"
            key="6"
            extra={
              <Popover content="Total">
                <Tag color="#e57373">
                  {pound + numberFormat(expenseTotal, 0, ".", ",")}
                </Tag>
              </Popover>
            }
          >
            <Collapse>
              <Panel
                header="Housing"
                key="6.1"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.housing.details.length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(housingTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.housing.details.push({
                          id: uuid(),
                          name: "Rent",
                          annual_expense: 0,
                          start_year: 2021,
                          end_year: 2021,
                          rate_after_retirement: 1,
                          type: "property",
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Housing
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Consumables"
                key="6.2"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.consumables.details
                          .length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(consumableTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.consumables.details.push({
                          id: uuid(),
                          name: "Eating Out",
                          annual_expense: 0,
                          rate_after_retirement: 1,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Consumables
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Travel"
                key="6.3"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.travel.details.length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(travelTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.travel.details.push({
                          id: uuid(),
                          name: "Travel",
                          annual_expense: 0,
                          rate_after_retirement: 1,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Travel
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Shopping"
                key="6.4"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.shopping.details.length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(shoppingTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.shopping.details.push({
                          id: uuid(),
                          name: "Clothing",
                          annual_expense: 0,
                          rate_after_retirement: 1,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Shopping
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Entertainment"
                key="6.5"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.entertainment.details
                          .length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(entertainmentTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.entertainment.details.push({
                          id: uuid(),
                          name: "Drinks",
                          annual_expense: 0,
                          rate_after_retirement: 1,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Entertainment
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Holiday"
                key="6.6"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.housing.details.length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(holidayTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.holiday.details.push({
                          id: uuid(),
                          name: "Flights",
                          annual_expense: 0,
                          rate_after_retirement: 1,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <Holiday
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
              <Panel
                header="Insurance Policies"
                key="6.7"
                extra={
                  <Popover content="Total">
                    <Tag color="magenta">
                      {pound + numberFormat(insuranceTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <Collapse>
                  <Panel
                    header="Life Insurance"
                    key="6.71"
                    extra={
                      <Popover content="Total">
                        <Tag color="magenta">
                          {pound +
                            numberFormat(lifeInsuranceTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <LifeInsurance
                      input={initialInputs}
                      setInput={setInitialInputs}
                      assumption={initialAssumption}
                      setAssumption={setInitialAssumption}
                    />
                  </Panel>
                  <Panel
                    header="Critical Illness Cover"
                    key="6.72"
                    extra={
                      <Popover content="Total">
                        <Tag color="magenta">
                          {pound +
                            numberFormat(criticalIllnessTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <CriticalIllness
                      input={initialInputs}
                      setInput={setInitialInputs}
                      assumption={initialAssumption}
                      setAssumption={setInitialAssumption}
                    />
                  </Panel>
                  <Panel
                    header="Family Income Benefit"
                    key="6.73"
                    extra={
                      <Popover content="Total">
                        <Tag color="magenta">
                          {pound + numberFormat(familyIncomeTotal, 0, ".", ",")}
                        </Tag>
                      </Popover>
                    }
                  >
                    <FamilyIncome
                      input={initialInputs}
                      setInput={setInitialInputs}
                      assumption={initialAssumption}
                      setAssumption={setInitialAssumption}
                    />
                  </Panel>
                </Collapse>
              </Panel>
              <Panel
                header="One-Off Expenses"
                key="6.8"
                extra={
                  <>
                    <Badge
                      showZero
                      count={
                        initialInputs.household_expenses.one_off_expenses.length
                      }
                      style={{
                        backgroundColor: "#e57373",
                        marginRight: "16px",
                      }}
                    />

                    <Popover content="Total">
                      <Tag color="magenta">
                        {pound + numberFormat(oneOffTotal, 0, ".", ",")}
                      </Tag>
                    </Popover>

                    <AddButton
                      onClick={() => {
                        const clone = { ...initialInputs };
                        clone.household_expenses.one_off_expenses.push({
                          id: uuid(),
                          name: "Renovation",
                          annual_payment_in_todays_terms: 0,
                          inflation: 0,
                          start_year: 2039,
                          end_year: 2041,
                        });
                        setInitialInputs(clone);
                      }}
                    />
                  </>
                }
              >
                <OneOff input={initialInputs} setInput={setInitialInputs} />
              </Panel>
              <Panel
                header="Children Education Expenses"
                key="6.9"
                extra={
                  <Popover content="Total">
                    <Tag color="magenta">
                      {pound +
                        numberFormat(childrenEducationTotal, 0, ".", ",")}
                    </Tag>
                  </Popover>
                }
              >
                <ChildrenEducation
                  input={initialInputs}
                  setInput={setInitialInputs}
                  assumption={initialAssumption}
                  setAssumption={setInitialAssumption}
                />
              </Panel>
            </Collapse>
          </Panel>
        </Collapse>
        <Affix
          offsetBottom={50}
          offsetTop={50}
          style={{ right: "50px", position: "absolute" }}
        >
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={loading}
          >
            Submit
          </Button>
        </Affix>
      </Form>
    </Layout>
  );
};

export default NewInputs;
