import React from "react";
import { Row, Col, Form, Typography } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import IAssumptions from "../../../../interfaces/IAssumptions";
import RateInput from "../../controls/RateInput";

const { Text } = Typography;

const GeneralInvestmentAccount = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  const initialAssumption: IAssumptions = prop.assumption;
  const setInitialAssumption = prop.setAssumption;

  return (
    <div>
      {initialInputs.assets.savings_and_investments.general_investment_account.map(
        (o: any, i: any) => {
          return (
            <Row key={"General" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text strong>{initialInputs.household_owners[i].name}</Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Original Balance">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.assets.savings_and_investments.general_investment_account[
                          i
                        ].original_balance = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.assets.savings_and_investments.general_investment_account[
                          i
                        ].original_balance = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.assets.savings_and_investments.general_investment_account[i].original_balance}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual Contributions">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.assets.savings_and_investments.general_investment_account[
                          i
                        ].annual_contribution = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.assets.savings_and_investments.general_investment_account[
                          i
                        ].annual_contribution = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.assets.savings_and_investments.general_investment_account[i].annual_contribution}`}
                  />
                </Form.Item>
              </Col>

              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Growth Rate">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialAssumption };
                        clone.market_data.savings_and_investment_growth_rate.rate =
                          +e;
                        setInitialAssumption(clone);
                      } else {
                        const clone = { ...initialAssumption };
                        clone.market_data.savings_and_investment_growth_rate.rate = 0;
                        setInitialAssumption(clone);
                      }
                    }}
                    value={`${initialAssumption.market_data.savings_and_investment_growth_rate.rate}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default GeneralInvestmentAccount;
