import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs } from "antd";
import IForecast from "../../../../interfaces/IForecast";
import { numberFormat } from "highcharts";

const { Title } = Typography;
const { TabPane } = Tabs;

const Pension = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [owner, setOwner] = useState([
    ...forecast[0].ages.owner_ages.map((o, i) => {
      return {
        key: "owner" + i,
        name: o.name,
      };
    }),
  ]);

  const [state, setState] = useState([
    ...forecast[0].household_income.pension_income.state_pension_income.details.map(
      (s: any, i) => {
        return {
          key: "state" + i,
          name: s.name,
        };
      }
    ),
  ]);

  const [totalState, setTotalState] = useState([
    {
      name: "Total State Pension",
    },
  ]);

  const [benefitLumpSump, setBenefitLumpSump] = useState(
    owner.map((o, i) => {
      return {
        key: "lumpSump" + i,
        name: "Lump Sump",
      };
    })
  );

  const [benefitAnnal, setBenefitAnnal] = useState(
    owner.map((o, i) => {
      return {
        key: "annal" + i,
        name: "Annal",
      };
    })
  );

  const [benefitTotal, setBenefitTotal] = useState(
    owner.map((o, i) => {
      return {
        key: "total" + i,
        name: "Personal Total",
      };
    })
  );

  const [totalBenefit, setTotalBenefit] = useState([
    {
      name: "Total Defined Benefit Pension",
    },
  ]);

  const [contibutionLumpSump, setContibutionLumpSump] = useState(
    owner.map((o, i) => {
      return {
        key: "Clump" + i,
        name: "Lump Sump Drawdown",
      };
    })
  );

  const [contibutionRegular, setContibutionRegular] = useState(
    owner.map((o, i) => {
      return {
        key: "CRegular" + i,
        name: "Regular Drqwdown",
      };
    })
  );

  const [contibutionInitiial, setContibutionInitial] = useState(
    owner.map((o, i) => {
      return {
        key: "cInitial" + i,
        name: "Annuity - Initial Drqwdown",
      };
    })
  );

  const [contibutionAnnuity, setContibutionAnnuity] = useState(
    owner.map((o, i) => {
      return {
        key: "cAnnuity" + i,
        name: "Annuity - Annuity Drqwdown",
      };
    })
  );

  const [contibutionTotal, setContibutionTotal] = useState(
    owner.map((o, i) => {
      return {
        key: "total" + i,
        name: "Personal Total",
      };
    })
  );

  const [totalContribution, setTotalContribution] = useState([
    {
      name: "Total Defined Contribution Pension",
    },
  ]);

  useEffect(() => {
    state.map((e: any, index) => {
      forecast.map((f, i) => {
        e["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income.state_pension_income.details[
              index
            ].amount
          ),
          0,
          ".",
          ","
        );
      });
    });

    let newTotalState: any = totalState;
    forecast.map((f, i) => {
      newTotalState[0]["year" + i] = numberFormat(
        Math.round(
          f.household_income.pension_income.state_pension_income.total
        ),
        0,
        ".",
        ","
      );
    });
    setTotalState(newTotalState);

    let newTotalBenfit: any = totalBenefit;
    forecast.map((f, i) => {
      newTotalBenfit[0]["year" + i] = numberFormat(
        Math.round(
          f.household_income.pension_income.defined_benefit_pension_income.total
        ),
        0,
        ".",
        ","
      );
    });
    setTotalBenefit(newTotalBenfit);

    let newTotalContribution: any = totalContribution;
    forecast.map((f, i) => {
      newTotalContribution[0]["year" + i] = numberFormat(
        Math.round(f.household_income.pension_income.total),
        0,
        ".",
        ","
      );
    });
    setTotalContribution(newTotalContribution);

    owner.map((o, index) => {
      let newBenefitLumpSump: any = [...benefitLumpSump];
      forecast.map((f, i) => {
        newBenefitLumpSump[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income.defined_benefit_pension_income
              .details[index].lump_sum
          ),
          0,
          ".",
          ","
        );
      });
      setBenefitLumpSump(newBenefitLumpSump);

      let newBenefitAnnal: any = [...benefitAnnal];
      forecast.map((f, i) => {
        newBenefitAnnal[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income.defined_benefit_pension_income
              .details[index].annual
          ),
          0,
          ".",
          ","
        );
      });
      setBenefitAnnal(newBenefitAnnal);

      let newBenefitTotal: any = [...benefitTotal];
      forecast.map((f, i) => {
        newBenefitTotal[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income.defined_benefit_pension_income
              .details[index].total
          ),
          0,
          ".",
          ","
        );
      });
      setBenefitTotal(newBenefitTotal);

      let newContributionLump: any = [...contibutionLumpSump];
      forecast.map((f, i) => {
        newContributionLump[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income
              .defined_contribution_pension_income.details[index]
              .lump_sum_drawdown_option
          ),
          0,
          ".",
          ","
        );
      });
      setContibutionLumpSump(newContributionLump);

      let newContributionRegular: any = [...contibutionRegular];
      forecast.map((f, i) => {
        newContributionRegular[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income
              .defined_contribution_pension_income.details[index]
              .regular_drawdown_option
          ),
          0,
          ".",
          ","
        );
      });
      setContibutionRegular(newContributionRegular);

      let newContributionInitial: any = [...contibutionInitiial];
      forecast.map((f, i) => {
        newContributionInitial[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income
              .defined_contribution_pension_income.details[index]
              .annuity_option_initial_drawdown
          ),
          0,
          ".",
          ","
        );
      });
      setContibutionInitial(newContributionInitial);

      let newContributionAnnuity: any = [...contibutionAnnuity];
      forecast.map((f, i) => {
        newContributionAnnuity[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income
              .defined_contribution_pension_income.details[index]
              .annuity_option_annuity_income
          ),
          0,
          ".",
          ","
        );
      });
      setContibutionAnnuity(newContributionAnnuity);

      let newContributionTotal: any = [...contibutionTotal];
      forecast.map((f, i) => {
        newContributionTotal[index]["year" + i] = numberFormat(
          Math.round(
            f.household_income.pension_income
              .defined_contribution_pension_income.details[index].total
          ),
          0,
          ".",
          ","
        );
      });
      setContibutionTotal(newContributionTotal);
    });
  }, [forecast]);

  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];

  return (
    <div>
      <Tabs defaultActiveKey="3.81" type="card">
        <TabPane tab="State Pension" key="3.81">
          <Table
            columns={Columns}
            dataSource={[...state, ...totalState]}
            scroll={{ x: true }}
            pagination={false}
          />
        </TabPane>
        <TabPane tab="Defined Benefit Pension" key="3.82">
          <Tabs defaultActiveKey="3.810" type="card">
            {owner.map((o, i) => {
              return (
                <TabPane tab={o.name} key={"3.81" + i}>
                  <Table
                    columns={Columns}
                    dataSource={[
                      benefitLumpSump[i],
                      benefitAnnal[i],
                      benefitTotal[i],
                    ]}
                    scroll={{ x: true }}
                    pagination={false}
                  />
                </TabPane>
              );
            })}

            <TabPane tab="Total Defined Benefit Pension" key="11">
              <Table
                columns={Columns}
                dataSource={[...totalBenefit]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          </Tabs>
        </TabPane>

        <TabPane tab="Defined Contribution Pension" key="3.83">
          <Tabs defaultActiveKey="3.830" type="card">
            {owner.map((o, i) => {
              return (
                <TabPane tab={o.name} key={"3.83" + i}>
                  <Table
                    columns={Columns}
                    dataSource={[
                      contibutionLumpSump[i],
                      contibutionRegular[i],
                      contibutionInitiial[i],
                      contibutionAnnuity[i],
                      contibutionTotal[i],
                    ]}
                    scroll={{ x: true }}
                    pagination={false}
                  />
                </TabPane>
              );
            })}

            <TabPane tab="Total Defined Contribution Pension" key="22">
              <Table
                columns={Columns}
                dataSource={[...totalContribution]}
                scroll={{ x: true }}
                pagination={false}
              />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Pension;
