import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

const AddButton = (props: any) => {
  return (
    <CloseCircleOutlined
      onClick={(e) => props.onClick(e)}
      style={{ color: "red" }}
    />
  );
};

export default AddButton;
