import React from "react";
import { Row, Col, Form, Typography, Select } from "antd";
import MoneyInput from "../../controls/MoneyInput";

const { Text } = Typography;
const { Option } = Select;

const DefinedContributionPension = (prop: any) => {
  const initialInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {" "}
      {initialInputs.household_income.pension_income.defined_contribution_pension_plans.map(
        (s: any, i: any) => {
          return (
            <Row key={"definedContribution" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text
                    strong
                    editable={{
                      onChange: (e) => {},
                    }}
                  >
                    {initialInputs.household_owners[i].name}
                  </Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Option taken:">
                  <Select
                    defaultValue="Lump Sum"
                    className="custom-input-fields"
                    onChange={(e) => {}}
                  >
                    <Option value="Lump Sum">Lump Sum</Option>
                    <Option value="Annual">Annual</Option>
                    <Option value="Drawdown">Drawdown</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual Amount:">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.defined_contribution_pension_plans[
                          i
                        ].drawdown_option_annual_amount = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.defined_contribution_pension_plans[
                          i
                        ].drawdown_option_annual_amount = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.pension_income.defined_contribution_pension_plans[i].drawdown_option_annual_amount}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default DefinedContributionPension;
