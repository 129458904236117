// TODO: Allow this to be set as an env variable
// export const apiRoute = "http://localhost:4000/";
export const apiRoute = "https://cashflow-api.adviceatom.com/";

//auth routes
export const loginRoute = `${apiRoute}api/auth/login/`;
export const registerRoute = `${apiRoute}api/auth/register/`;

//input routes
export const clientRoute = `${apiRoute}api/client/`;

//assumption routes
export const assummptionsRoute = `${apiRoute}api/assumptions/`;

//forecast routes
export const forecastRoute = `${apiRoute}api/forecast/`;
export const summaryRoute = `${apiRoute}api/summary/`;

//input routes
export const inputsRoute = `${apiRoute}api/inputs/`;

//events routes
export const eventsRoute = `${apiRoute}api/events/`;
