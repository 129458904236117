import React from "react";
import { Row, Col, Form, Typography, Select, InputNumber } from "antd";
import MoneyInput from "../../controls/MoneyInput";
import RateInput from "../../controls/RateInput";
import { IInputs } from "../../../../interfaces/ISubInputs";
import DateInput from "../../controls/DateInput";

const { Text } = Typography;
const { Option } = Select;

const StatePension = (prop: any) => {
  const initialInputs: IInputs = prop.input;
  const setInitialInputs = prop.setInput;
  return (
    <div>
      {" "}
      {initialInputs.household_income.pension_income.defined_benifit_pension_plans.map(
        (s: any, i: any) => {
          return (
            <Row key={"definedBenifit" + i}>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label=" ">
                  <Text strong>{initialInputs.household_owners[i].name}</Text>
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Annual Amount">
                  <MoneyInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.state_pension[
                          i
                        ].annual_amount = +e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.state_pension[
                          i
                        ].annual_amount = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.pension_income.state_pension[i].annual_amount}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Inflation">
                  <RateInput
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.state_pension[
                          i
                        ].inflation = e;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.state_pension[
                          i
                        ].inflation = 0;
                        setInitialInputs(clone);
                      }
                    }}
                    value={`${initialInputs.household_income.pension_income.state_pension[i].inflation}`}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="State Pension Age">
                  <InputNumber
                    className="custom-input-fields"
                    name="state_pension_age"
                    value={initialInputs.household_income.pension_income.state_pension[
                      i
                    ].state_pension_age.toString()}
                    onBlur={(e) => {
                      if (e) {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.state_pension[
                          i
                        ].state_pension_age = +e.target.value;
                        setInitialInputs(clone);
                      } else {
                        const clone = { ...initialInputs };
                        clone.household_income.pension_income.state_pension[
                          i
                        ].state_pension_age = 65;
                        setInitialInputs(clone);
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="Start year:">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_income.pension_income.state_pension[
                        i
                      ].start_year = +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_income.pension_income.state_pension[
                      i
                    ].start_year.toString()}
                  />
                </Form.Item>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={24}
                xs={24}
                className="custom-input-fields"
              >
                <Form.Item label="End year:">
                  <DateInput
                    onBlur={(date, dateString) => {
                      const clone = { ...initialInputs };
                      clone.household_income.pension_income.state_pension[
                        i
                      ].end_year = +dateString;
                      setInitialInputs(clone);
                    }}
                    value={initialInputs.household_income.pension_income.state_pension[
                      i
                    ].end_year.toString()}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        }
      )}
    </div>
  );
};

export default StatePension;
