import { renderToStaticMarkup } from "react-dom/server";
import { ReactElement } from "react";

const iconColor = "white";

function encodeSvg(reactElement: ReactElement) {
  return `url(data:image/svg+xml,${escape(renderToStaticMarkup(reactElement))})`;
}

// test
function encodeSvg2(reactElement: ReactElement) {
  return `data:image/svg+xml,${escape(renderToStaticMarkup(reactElement))}`;
}

function CommonIcon(props: any) {
  const path = props.path;
  const path2 = props.path2;
  const path3 = props.path3;
  const color = props.color;
  return (
    <svg
      id="myID"
      style={{ color: iconColor, height: "100px" }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      focusable="false"
      height={40}
      width={40}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
    >
      <rect width="20" height="20" rx="4" ry="4" fill={color} />
      <path d={path} fill="currentColor" />
      <path d={path2} fill="currentColor" />
      <path d={path3} fill="currentColor" />
    </svg>
  );
}

function CommonIconEvents(props: any) {
  const path = props.path;
  const path2 = props.path2;
  const path3 = props.path3;
  const color = props.color;
  return (
    <svg
      id="myID"
      style={{ color: iconColor, height: "100px" }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      focusable="false"
      height={40}
      width={40}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" rx="4" ry="4" fill={color} />
      <path d={path} fill="currentColor" />
      <path d={path2} fill="currentColor" />
      <path d={path3} fill="currentColor" />
    </svg>
  );
}

export const startIcon = (color: string) => {
  return encodeSvg(
    <CommonIcon
      color={color}
      path="M17.22 8.687a1.498 1.498 0 0 1 0 2.626l-9.997 5.499A1.5 1.5 0 0 1 5 15.499V4.501a1.5 1.5 0 0 1 2.223-1.313l9.997 5.499zm-.482 1.75a.5.5 0 0 0 0-.875L6.741 4.063A.5.5 0 0 0 6 4.501v10.998a.5.5 0 0 0 .741.438l9.997-5.5z"
    />
  );
};
export const endIcon = (color: string) => {
  return encodeSvg(
    <CommonIcon
      color={color}
      path="M15.5 4a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 3 4.5v11A1.5 1.5 0 0 0 4.5 17h11a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 15.5 3h-11z"
    />
  );
};

export const bottleIcon = (color: string) => {
  return encodeSvg(
    <CommonIcon
      color={color}
      path="M10.002 4.43a.5.5 0 0 1 .566-.425a2.86 2.86 0 0 1 2.424 2.421a.5.5 0 0 1-.99.143a1.86 1.86 0 0 0-1.575-1.574a.5.5 0 0 1-.425-.565z"
      path2="M5 7a5 5 0 0 1 10 0a8.313 8.313 0 0 1-1.598 4.859c-.791 1.074-1.837 1.892-2.902 2.093V14a1 1 0 0 0 1 1H14a2 2 0 0 1 2 2v.5a.5.5 0 0 1-1 0V17a1 1 0 0 0-1-1h-2.5a2 2 0 0 1-2-2v-.048c-1.065-.2-2.11-1.017-2.902-2.091A8.311 8.311 0 0 1 5 7zm5-4a4 4 0 0 0-4 4c0 1.637.585 3.16 1.402 4.268C8.237 12.399 9.232 13 10 13c.768 0 1.763-.602 2.598-1.734A7.313 7.313 0 0 0 14 7a4 4 0 0 0-4-4z"
    />
  );
};

export const umbrellaIcon = (color: string) => {
  return encodeSvg(
    <CommonIcon
      color={color}
      path="M8.263 4.608a5.214 5.214 0 0 1 9.032 5.214l-.197.34a.5.5 0 0 1-.683.183l-3.465-2l-1.931 3.38c1.34.407 2.492 1.331 3.315 2.528a1.499 1.499 0 0 0-1.01.307c-.96-1.254-2.336-2.06-3.824-2.06s-2.864.806-3.824 2.06a1.498 1.498 0 0 0-1.01-.307C5.786 12.625 7.513 11.5 9.5 11.5c.163 0 .324.008.483.022l2.101-3.677L8.25 5.63a.5.5 0 0 1-.183-.683l.196-.34zm.921.408l1.703.983a.596.596 0 0 1 .011-.017c.536-.741 1.04-1.31 1.699-1.808a9.21 9.21 0 0 1 1.574-.938a4.216 4.216 0 0 0-4.987 1.78zm4.82 2.782c.465-.816.688-1.46.766-2.085c.063-.503.035-1.016-.059-1.627c-.629.299-1.106.579-1.51.885c-.536.406-.963.873-1.444 1.53l2.247 1.297zm.866.5l1.611.93a4.216 4.216 0 0 0-.742-5.014c.071.56.09 1.088.023 1.623c-.099.791-.38 1.563-.892 2.461zm-9.632 7.295a.5.5 0 0 0-.96.038c-.17.695-.492 1.076-.859 1.297c-.384.232-.873.322-1.419.322a.5.5 0 0 0 0 1c.638 0 1.333-.102 1.936-.465a2.66 2.66 0 0 0 .884-.873c1.193 1.521 3.522 1.514 4.68-.016c1.177 1.554 3.56 1.537 4.735-.057A3.46 3.46 0 0 0 17 18.25a.5.5 0 0 0 0-1c-.983 0-1.963-.64-2.287-1.652a.5.5 0 0 0-.95-.005c-.643 1.939-3.16 1.914-3.79.08a.5.5 0 0 0-.946 0c-.63 1.834-3.147 1.859-3.79-.08z"
    />
  );
};

export const bookIcon = (color: string) => {
  return encodeSvg(
    <CommonIcon
      color={color}
      path="M10 16c-.456.607-1.182 1-2 1H3.5A1.5 1.5 0 0 1 2 15.5v-11A1.5 1.5 0 0 1 3.5 3H8c.818 0 1.544.393 2 1c.456-.607 1.182-1 2-1h4.5A1.5 1.5 0 0 1 18 4.5v11a1.5 1.5 0 0 1-1.5 1.5H12a2.496 2.496 0 0 1-2-1zM3 4.5v11a.5.5 0 0 0 .5.5H8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 8 4H3.5a.5.5 0 0 0-.5.5zm7.5 10A1.5 1.5 0 0 0 12 16h4.5a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5H12a1.5 1.5 0 0 0-1.5 1.5v9z"
    />
  );
};

export const hatIcon = (color: string) => {
  return encodeSvg(
    <CommonIcon
      color={color}
      path="M8.506 3.401a3 3 0 0 1 2.988 0l7.255 4.166a.5.5 0 0 1 0 .867L16 10.012V14.5a.5.5 0 0 1-.146.354l-.002.001l-.002.003l-.007.006l-.023.022l-.08.074c-.07.061-.17.147-.301.248a8.104 8.104 0 0 1-1.141.733A9.092 9.092 0 0 1 10 17.001a9.092 9.092 0 0 1-4.298-1.06a8.1 8.1 0 0 1-1.14-.733a5.832 5.832 0 0 1-.382-.322A.532.532 0 0 1 4 14.5v-4.488L2 8.864V13.5a.5.5 0 1 1-1 0V8a.5.5 0 0 1 .26-.439l7.246-4.16zm2.988 9.198a3 3 0 0 1-2.988 0L5 10.586v3.691a7.11 7.11 0 0 0 1.173.782c.88.47 2.175.941 3.827.941a8.093 8.093 0 0 0 3.827-.94A7.106 7.106 0 0 0 15 14.276v-3.69l-3.506 2.012zm-.498-8.33a2 2 0 0 0-1.992 0L2.504 8l6.5 3.732a2 2 0 0 0 1.992 0L17.496 8l-6.5-3.732z"
    />
  );
};

//Event Icons

export const boatIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M13 3v1h-2V3h2m-1 7.11l5.38 1.77l2.39.78l-1.12 3.97c-.54-.3-.94-.71-1.14-.94L16 13.96l-1.51 1.72c-.34.4-1.28 1.32-2.49 1.32s-2.15-.92-2.49-1.32L8 13.96l-1.51 1.72c-.2.23-.6.63-1.14.93l-1.13-3.96l2.4-.79L12 10.11M15 1H9v3H6c-1.1 0-2 .9-2 2v4.62l-1.29.42a1.007 1.007 0 0 0-.66 1.28L3.95 19H4c1.6 0 3.02-.88 4-2c.98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68c.08-.26.06-.54-.06-.78s-.34-.42-.6-.5L20 10.62V6c0-1.1-.9-2-2-2h-3V1zM6 9.97V6h12v3.97L12 8L6 9.97zm10 9.71c-1.22.85-2.61 1.28-4 1.28s-2.78-.43-4-1.28C6.78 20.53 5.39 21 4 21H2v2h2c1.38 0 2.74-.35 4-.99c1.26.64 2.63.97 4 .97s2.74-.32 4-.97c1.26.65 2.62.99 4 .99h2v-2h-2c-1.39 0-2.78-.47-4-1.32z"
    />
  );
};

export const partyIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M17 2H7c-1.1 0-2 .9-2 2v16c0 1.1.9 1.99 2 1.99L17 22c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM7 20V4h10v16H7zm5-11c1.1 0 2-.9 2-2s-.9-2-2-2a2 2 0 1 0 0 4zm0 2c-2.21 0-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2z"
    />
  );
};

export const universityIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M9.909 3.7a3.875 3.875 0 0 1 4.182 0l8.064 5.169a.75.75 0 0 1 .005 1.259L19 12.193v5.557a.75.75 0 0 1-.15.45l-.001.001l-.001.002l-.003.004l-.009.01l-.015.02a7.428 7.428 0 0 1-1.662 1.401C16.036 20.33 14.339 21 12 21s-4.036-.67-5.159-1.361a7.427 7.427 0 0 1-1.25-.957a5.317 5.317 0 0 1-.316-.33a2.719 2.719 0 0 1-.124-.15L5.15 18.2a.75.75 0 0 1-.15-.45v-5.557l-2-1.307v5.364a.75.75 0 0 1-1.5 0V9.5a.75.75 0 0 1 .358-.64l8.05-5.16zm4.21 11.681a3.875 3.875 0 0 1-4.238 0L6.5 13.172v4.297a5.934 5.934 0 0 0 1.127.893C8.536 18.92 9.964 19.5 12 19.5s3.464-.58 4.373-1.139a5.935 5.935 0 0 0 1.127-.892v-4.297l-3.38 2.21zm-.837-10.419c-.781-.5-1.783-.5-2.564 0L3.63 9.506l7.071 4.62c.79.515 1.809.515 2.598 0l7.07-4.62l-7.087-4.544z"
    />
  );
};

export const childCareIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M18.75 10.08L17.4 6.05A2.978 2.978 0 0 0 14.56 4H9.44C8.15 4 7 4.82 6.6 6.05L5.81 8.4L4.41 7l.29-.29A.996.996 0 1 0 3.29 5.3l-2 2A.996.996 0 1 0 2.7 8.71l.3-.3l1.79 1.79C3.18 10.72 2 12.22 2 14c0 1.49.83 2.78 2.05 3.47A2.991 2.991 0 0 0 7 20c1.3 0 2.4-.84 2.82-2h4.37c.41 1.16 1.51 2 2.82 2c1.49 0 2.73-1.1 2.95-2.53C21.17 16.78 22 15.49 22 14c0-1.95-1.4-3.57-3.25-3.92zM13 6h1.56a1 1 0 0 1 .95.68l1.1 3.32H13V6zm-4.51.68A1 1 0 0 1 9.44 6H11v4H7.41l-.02-.02l1.1-3.3zM7 18c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm10 0c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm2.49-2.68A3.028 3.028 0 0 0 17 14c-1.3 0-2.4.84-2.82 2H9.82C9.4 14.84 8.3 14 7 14c-1.03 0-1.95.53-2.49 1.32C4.2 14.97 4 14.51 4 14c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2c0 .51-.2.97-.51 1.32z"
    />
  );
};

export const houseIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M19 9.3V4h-3v2.6L12 3L2 12h3v8h6v-6h2v6h6v-8h3l-3-2.7zM17 18h-2v-6H9v6H7v-7.81l5-4.5l5 4.5V18z"
      path2="M10 10h4c0-1.1-.9-2-2-2s-2 .9-2 2z"
    />
  );
};

export const inheritanceIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M10.5 8a3 3 0 1 0 0 6a3 3 0 0 0 0-6zM9 11a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0z"
      path2="M2 7.25A2.25 2.25 0 0 1 4.25 5h12.5A2.25 2.25 0 0 1 19 7.25v7.5A2.25 2.25 0 0 1 16.75 17H4.25A2.25 2.25 0 0 1 2 14.75v-7.5zm2.25-.75a.75.75 0 0 0-.75.75V8h.75A.75.75 0 0 0 5 7.25V6.5h-.75zm-.75 6h.75a2.25 2.25 0 0 1 2.25 2.25v.75h8v-.75a2.25 2.25 0 0 1 2.25-2.25h.75v-3h-.75a2.25 2.25 0 0 1-2.25-2.25V6.5h-8v.75A2.25 2.25 0 0 1 4.25 9.5H3.5v3zm14-4.5v-.75a.75.75 0 0 0-.75-.75H16v.75c0 .414.336.75.75.75h.75zm0 6h-.75a.75.75 0 0 0-.75.75v.75h.75a.75.75 0 0 0 .75-.75V14zm-14 .75c0 .414.336.75.75.75H5v-.75a.75.75 0 0 0-.75-.75H3.5v.75z"
      path3="M4.401 18.5A2.999 2.999 0 0 0 7 20h10.25A4.75 4.75 0 0 0 22 15.25V10c0-1.11-.603-2.08-1.5-2.599v7.849a3.25 3.25 0 0 1-3.25 3.25H4.401z"
    />
  );
};

export const midLifeCrisisIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 7h10.29l1.04 3H5.81l1.04-3zM19 17H5v-5h14v5z"
    />
  );
};

export const partTimeJobIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8zm.5-13H11v6l5.25 3.15l.75-1.23l-4.5-2.67z"
    />
  );
};

export const remodelIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M13.78 15.3l6 6l2.11-2.16l-6-6l-2.11 2.16m3.72-5.2c-.39 0-.81-.05-1.14-.19L4.97 21.25l-2.11-2.11l7.41-7.4L8.5 9.96l-.72.7l-1.45-1.41v2.86l-.7.7l-3.52-3.56l.7-.7h2.81l-1.4-1.41l3.56-3.56a2.976 2.976 0 0 1 4.22 0L9.89 5.74l1.41 1.4l-.71.71l1.79 1.78l1.82-1.88c-.14-.33-.2-.75-.2-1.12a3.49 3.49 0 0 1 3.5-3.52c.59 0 1.11.14 1.58.42L16.41 6.2l1.5 1.5l2.67-2.67c.28.47.42.97.42 1.6c0 1.92-1.55 3.47-3.5 3.47z"
    />
  );
};

export const startBusinessIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M21.9 8.89l-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89c-.24 1.02-.02 2.06.62 2.88c.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6.94c.09-.09.2-.18.28-.28c.64-.82.87-1.87.62-2.89zm-2.99-3.9l1.05 4.37c.1.42.01.84-.25 1.17c-.14.18-.44.47-.94.47c-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01zM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07c-.22.26-.54.41-.95.41c-.67 0-1.22-.59-1.22-1.31V5zM8.49 9.52L9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31c-.34 0-.65-.15-.89-.41a1.42 1.42 0 0 1-.33-1.07zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14c-.49 0-.8-.29-.93-.47c-.27-.32-.36-.75-.26-1.17zM5 19v-6.03c.08.01.15.03.23.03c.87 0 1.66-.36 2.24-.95c.6.6 1.4.95 2.31.95c.87 0 1.65-.36 2.23-.93c.59.57 1.39.93 2.29.93c.84 0 1.64-.35 2.24-.95c.58.59 1.37.95 2.24.95c.08 0 .15-.02.23-.03V19H5z"
    />
  );
};

export const travelIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M9.47 9.643L8.768 3.77l-.002-.074c0-.889.72-1.61 1.61-1.61c.802 0 1.545.42 1.977 1.135l.087.161l2.945 6.09l3.957-.117a2.6 2.6 0 0 1 2.672 2.53l.001.066a2.602 2.602 0 0 1-2.62 2.597l-3.963-.116l-2.992 6.188a2.293 2.293 0 0 1-2.065 1.295c-.889 0-1.609-.72-1.609-1.631l.007-.118l.707-5.908l-2.132-.063l-.27.736a1.946 1.946 0 0 1-1.827 1.278c-.876 0-1.586-.71-1.586-1.587v-.76l-.154-.032a1.92 1.92 0 0 1 0-3.758l.155-.032v-.76c0-.803.597-1.475 1.434-1.579l.151-.008c.745 0 1.423.426 1.765 1.127l.063.15l.27.736l2.12-.062zm.906-6.057c-.06 0-.109.049-.11.087l.887 7.422l-4.84.141l-.628-1.715l-.032-.079c-.075-.152-.23-.25-.354-.251l-.058.002a.086.086 0 0 0-.075.085l.001 1.98l-1.35.282a.419.419 0 0 0 0 .821l1.35.281v1.98c0 .047.038.086.085.086a.446.446 0 0 0 .419-.293l.64-1.751l4.854.141l-.897 7.471v.03c0 .06.048.108.108.108a.793.793 0 0 0 .714-.448l3.415-7.063l4.914.144c.606 0 1.097-.491 1.097-1.086v-.043a1.097 1.097 0 0 0-1.13-1.064l-4.928.144l-3.351-6.932l-.053-.099a.793.793 0 0 0-.678-.381z"
    />
  );
};

export const weddingIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M12 10L8 4.4L9.6 2h4.8L16 4.4L12 10m3.5-3.2l-1.2 1.7c2.2.9 3.7 3 3.7 5.5a6 6 0 0 1-6 6a6 6 0 0 1-6-6c0-2.5 1.5-4.6 3.7-5.5L8.5 6.8C5.8 8.1 4 10.8 4 14a8 8 0 0 0 8 8a8 8 0 0 0 8-8c0-3.2-1.8-5.9-4.5-7.2z"
    />
  );
};

export const otherIcon = (color: string) => {
  return encodeSvg(
    <CommonIconEvents
      color={color}
      path="M12 13a5 5 0 0 1-5-5h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2a5 5 0 0 1-5 5m0-10a3 3 0 0 1 3 3H9a3 3 0 0 1 3-3m7 3h-2a5 5 0 0 0-5-5a5 5 0 0 0-5 5H5c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2z"
    />
  );
};
