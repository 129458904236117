import { Table } from "antd";
import { numberFormat } from "highcharts";
import React, { useState, useEffect } from "react";
import IForecast from "../../../../interfaces/IForecast";

const EmployersPension = (props: any) => {
  const forecast: IForecast[] = props.forecast;

  const [employer, setEmployer] = useState([
    ...forecast[0].household_income.employer_pension_contribution.details.map(
      (m: any, i) => {
        return {
          key: "income" + i,
          name: m.name,
        };
      }
    ),
  ]);

  useEffect(() => {
    employer.map((s: any, index) => {
      forecast.map((f, i) => {
        s["year" + i] = numberFormat(
          Math.round(
            f.household_income.employer_pension_contribution.details[index]
              .amount
          ),
          0,
          ".",
          ","
        );
      });
    });
  }, [forecast]);
  const Columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    ...forecast.map((f, i) => {
      return {
        title: f.year.toString(),
        dataIndex: "year" + i,
        key: "year",
      };
    }),
  ];
  const data = [...employer];

  return (
    <div>
      <Table
        columns={Columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </div>
  );
};

export default EmployersPension;
