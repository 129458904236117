import { Table, Typography } from "antd";
import { numberFormat } from "highcharts";
import React, { Fragment } from "react";

import { RootStateOrAny, useSelector } from "react-redux";
import { pound } from "../../../../components/currencySumbol";
import IInputs from "../../../../interfaces/IInputs";
import {
  firstColumnWidth,
  secondColumnWidth,
  thirdColumnWidth,
} from "../../CommonVariable";
import ViewButton from "../sharedComponents/ViewButton";

const { Text } = Typography;

const OtherIncome = () => {
  const inputs: IInputs = useSelector(
    (state: RootStateOrAny) => state.currentInputSetReducer
  );

  // Other Taxable Income
  const columnsTaxable: any = [
    {
      dataIndex: "name",
      width: firstColumnWidth,
    },
    {
      dataIndex: "gross_annual_amount",
      width: secondColumnWidth,
      align: "right",
      render: (text: any, record: any) => (
        <Text>
          {pound}
          {inputs.current_year <= record.end_year &&
          inputs.current_year >= record.start_year
            ? numberFormat(text, 0, ".", ",")
            : 0}
        </Text>
      ),
    },
    {
      dataIndex: "action",
      width: thirdColumnWidth,
      render: (text: any, record: any) => <ViewButton />,
    },
  ];

  const dataTaxable = inputs.household_income.other_income.other_taxable_income;

  // Other Non-Taxable Income
  const columnsNonTaxable: any = [
    {
      dataIndex: "name",
      width: firstColumnWidth,
    },
    {
      dataIndex: "gross_annual_amount",
      width: secondColumnWidth,
      align: "right",
      render: (text: any, record: any) => (
        <Text>
          {pound}
          {inputs.current_year <= record.end_year &&
          inputs.current_year >= record.start_year
            ? numberFormat(text, 0, ".", ",")
            : 0}
        </Text>
      ),
    },
    {
      dataIndex: "action",
      width: thirdColumnWidth,
      render: (text: any, record: any) => <ViewButton />,
    },
  ];

  const dataNonTaxable =
    inputs.household_income.other_income.other_non_taxable_income;

  return (
    <Fragment>
      <Text strong>Other Taxable</Text>
      <Table
        size="small"
        columns={columnsTaxable}
        dataSource={dataTaxable}
        showHeader={false}
        pagination={false}
        bordered={false}
      />

      <Text strong>Other Non-Taxable</Text>
      <Table
        size="small"
        columns={columnsNonTaxable}
        dataSource={dataNonTaxable}
        showHeader={false}
        pagination={false}
        bordered={false}
      />
    </Fragment>
  );
};

export default OtherIncome;
